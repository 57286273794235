import React,{Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';

class SummaryMarketInfoTable extends Component{
    componentWillMount(){

    }
    render(){
        return (
            <div className={this.props.isHome?"tableHome":"tableAway"}>
                <div className="tableFull-width">
                    <div className={this.props.isHome?"tableHeader-home":"tableHeader-away"}>
                        <div className="tableHeading">{this.props.title}
                        </div>

                    </div>
                    <table className="tableDefault tableDefault-bg">

                        <thead>
                            <tr>
                                <th></th>
                                <th className="w-100px">{this.props.staticKeyWords.high}</th>
                                <th className="w-100px">{this.props.staticKeyWords.low}</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div>{this.props.staticKeyWords.all}</div>
                                </td>
                                <td>
                                    <div>{this.props.data.allHigh}</div>
                                </td>
                                <td>
                                    <div>{this.props.data.allLow}</div>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <div>{this.props.staticKeyWords.home}</div>
                                </td>
                                <td>
                                    <div>{this.props.data.homeTeamHigh}</div>
                                </td>
                                <td>
                                    <div>{this.props.data.homeTeamLow}</div>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <div>{this.props.staticKeyWords.away}</div>
                                </td>
                                <td>
                                    <div>{this.props.data.awayTeamHigh}</div>
                                </td>
                                <td>
                                    <div>{this.props.data.awayTeamLow}</div>
                                </td>

                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>
        );
    }
}


function mapStateToProps(state) {
  return {
    staticKeyWords: state.app.staticKeyWords
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({
    }, dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(SummaryMarketInfoTable);
