import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getFixturesResultsData, 
         setCarouselData, 
         selectCarouselItem, 
         getFixtureResultsFilters, 
         setParamsData, 
         setFilterOptions, 
         getTeamFixturesData, 
         getFixturesResultsSeasons,
         generateCarouselData,
         setLeagueSeason } from './FixturesResultsSlice';
import { formatFixtureResultsDate, fullMonths, formatDate } from '../../sharedfiles/helper';
import Loading from '../loading/Loading';

class FixturesResults extends Component {
    componentWillMount() {
        this.props.dispatch(getFixtureResultsFilters(this.props.languageId));
        

        console.log("path ===", this.props.match.path.includes('league'));
        // this.props.dispatch(getFiltersData(1));

        let carouselData = [];

        for (var i = 11; i >= 0; i--) {
            var dt = new Date();
            console.log("carouselData==dt==", dt)
            dt.setMonth(dt.getMonth() - i);
            console.log("carouselData==dt2==", dt)
            carouselData.push({
                id: dt.getFullYear() +""+ (dt.getMonth() + 1),
                fullDate: dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
                date: dt.getDate(),
                month: dt.getMonth() + 1,
                year: dt.getFullYear()
            })
        }
        for (var i = 1; i <= 11; i++) {
            var dt = new Date();
            console.log("carouselData==dt==", dt)
            dt.setMonth(dt.getMonth() + i);
            console.log("carouselData==dt2==", dt)
            carouselData.push({
                id: dt.getFullYear() +""+ (dt.getMonth() + 1),
                fullDate: dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
                date: dt.getDate(),
                month: dt.getMonth() + 1,
                year: dt.getFullYear()
            })
        }
        var dt = new Date();
        var selectedCarousel = {
            id: dt.getFullYear() +""+ (dt.getMonth() + 1),
            fullDate: dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
            date: dt.getDate(),
            month: dt.getMonth() + 1,
            year: dt.getFullYear()
        }
        //this.props.dispatch(selectCarouselItem(selectedCarousel))
        //this.props.dispatch(setCarouselData(carouselData));
        if (this.props.match && this.props.match.params) {
            let params = this.getParams(this.props);
            params.date = selectedCarousel.year+ '-' + selectedCarousel.month;
            if (Object.keys(params).length > 0) {
                this.props.dispatch(getFixturesResultsSeasons(params, this.props.languageId));
                if(!params.isLeague){
                    this.props.dispatch(getFixturesResultsData(params, this.props.languageId, this.props.fixturesresults));
                }
                

            }
        }
    }
    getParams(props) {
        if (props.type === 'league') {
            if (props.match && props.match.params.leagueId && props.match.params.leagueId > 0) {
                return {
                    isLeague: true,
                    compId: Number(props.match.params.leagueId),
                    teamId: "",
                    date: this.props.selectedCarouselItem.year + '-' + this.props.selectedCarouselItem.month
                }
            }
        } else {
            return {
                isLeague: false,
                compId: "",
                teamId: Number(props.match.params.teamId),
                date: this.props.selectedCarouselItem.year + '-' + this.props.selectedCarouselItem.month,
                selectedSeason: this.props.fixturesresults.selectedSeason,
                selectedLeague: this.props.fixturesresults.selectedLeague,
                selectedHHAD: this.props.fixturesresults.selectedHHAD,
                selectedHDC: this.props.fixturesresults.selectedHDC,
                selectedCorner: this.props.fixturesresults.selectedCorner,
                selectedOption: this.props.fixturesresults.selectedOption
            }

        }
        return {};
    }
    componentWillReceiveProps(nextProps) {
        console.log("next props==", nextProps);
        let params = this.getParams(nextProps);
        if (this.props.match &&
            this.props.match.params && JSON.stringify(this.props.match.params) !== JSON.stringify(nextProps.match.params)) {
            if ((window.location.pathname.includes('team') &&
                this.props.match.params.teamId !== nextProps.match.params.teamId) ||
                (window.location.pathname.includes('league') &&
                    this.props.match.params.leagueId !== nextProps.match.params.leagueId) || (
                    this.props.match.path !== nextProps.match.path
                )) {
                
                if(!params.isLeague){
                    this.props.dispatch(getFixturesResultsData(params, this.props.languageId));
                }
                
                this.props.dispatch(getFixturesResultsSeasons(params, this.props.languageId))
            }

        }
        if(params.isLeague && JSON.stringify(this.props.selectedCarouselItem) !== JSON.stringify(nextProps.selectedCarouselItem)){
            params.date = nextProps.selectedCarouselItem.year + '-' + nextProps.selectedCarouselItem.month;
            this.props.dispatch(getFixturesResultsData(params, this.props.languageId));
        }
    }
    componentDidMount() {
        // console.log("carousel data length=="+this.props.carouselData.length)
        // this.slider.slickGoTo(this.props.carouselData.length)
    }
    selectItem(item) {
        this.props.dispatch(selectCarouselItem(item))
        // let params = this.getParams(this.props);
        // params.date = item.year + '-' + item.month;
        //this.props.dispatch(getFixturesResultsData(params, this.props.languageId));
    }
    leagueSeasonChanged(event){
        if(event.target.value && event.target.value.length>0){
            var data = JSON.parse(event.target.value)
            if(data && Object.keys(data).length>0){
                this.props.dispatch(setLeagueSeason(data));
                this.props.dispatch(generateCarouselData(data));
            }
        }
    }
    seasonChanged(event) {
        this.props.dispatch(setFilterOptions({ "key": 'Season', "value": event.target.value }));
        this.props.dispatch(getFixturesResultsData({
            isLeague: false,
            teamId: this.props.match.params.teamId,
            selectedSeason: event.target.value,
            selectedLeague: this.props.fixturesresults.selectedLeague,
            selectedHHAD: this.props.fixturesresults.selectedHHAD,
            selectedHDC: this.props.fixturesresults.selectedHDC,
            selectedCorner: this.props.fixturesresults.selectedCorner,
            selectedOption: this.props.fixturesresults.selectedOption
        }, this.props.languageId))
    }
    leagueChanged(event) {
        this.props.dispatch(setFilterOptions({ "key": 'Team_League', "value": event.target.value }));
        this.props.dispatch(getFixturesResultsData({
            isLeague: false,
            teamId: this.props.match.params.teamId,
            selectedSeason: this.props.fixturesresults.selectedSeason,
            selectedLeague: event.target.value,
            selectedHHAD: this.props.fixturesresults.selectedHHAD,
            selectedHDC: this.props.fixturesresults.selectedHDC,
            selectedCorner: this.props.fixturesresults.selectedCorner,
            selectedOption: this.props.fixturesresults.selectedOption
        }, this.props.languageId))
    }
    hhadChanged(event) {
        this.props.dispatch(setFilterOptions({ "key": 'Team_HHAD', "value": event.target.value }));
        this.props.dispatch(getFixturesResultsData({
            isLeague: false,
            teamId: this.props.match.params.teamId,
            selectedSeason: this.props.fixturesresults.selectedSeason,
            selectedLeague: this.props.fixturesresults.selectedLeague,
            selectedHHAD: event.target.value,
            selectedHDC: this.props.fixturesresults.selectedHDC,
            selectedCorner: this.props.fixturesresults.selectedCorner,
            selectedOption: this.props.fixturesresults.selectedOption
        }, this.props.languageId))
    }
    hdcChanged(event) {
        this.props.dispatch(setFilterOptions({ "key": 'Team_HDC', "value": event.target.value }));
        this.props.dispatch(getFixturesResultsData({
            isLeague: false,
            teamId: this.props.match.params.teamId,
            selectedSeason: this.props.fixturesresults.selectedSeason,
            selectedLeague: this.props.fixturesresults.selectedLeague,
            selectedHHAD: this.props.fixturesresults.selectedHHAD,
            selectedHDC: event.target.value,
            selectedCorner: this.props.fixturesresults.selectedCorner,
            selectedOption: this.props.fixturesresults.selectedOption
        }, this.props.languageId))
    }
    cornersChanged(event) {
        this.props.dispatch(setFilterOptions({ "key": 'Team_Corner', "value": event.target.value }));
        this.props.dispatch(getFixturesResultsData({
            isLeague: false,
            teamId: this.props.match.params.teamId,
            selectedSeason: this.props.fixturesresults.selectedSeason,
            selectedLeague: this.props.fixturesresults.selectedLeague,
            selectedHHAD: this.props.fixturesresults.selectedHHAD,
            selectedHDC: this.props.fixturesresults.selectedHDC,
            selectedCorner: event.target.value,
            selectedOption: this.props.fixturesresults.selectedOption
        }, this.props.languageId))
    }
    optionsChanged(event) {
        this.props.dispatch(setFilterOptions({ "key": 'Options', "value": event.target.value }));
        this.props.dispatch(getFixturesResultsData({
            isLeague: false,
            teamId: this.props.match.params.teamId,
            selectedSeason: this.props.fixturesresults.selectedSeason,
            selectedLeague: this.props.fixturesresults.selectedLeague,
            selectedHHAD: this.props.fixturesresults.selectedHHAD,
            selectedHDC: this.props.fixturesresults.selectedHDC,
            selectedCorner: this.props.fixturesresults.selectedCorner,
            selectedOption: event.target.value,
        }, this.props.languageId))
    }
    render() {
        console.log('haha...', this.props)
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 6,
            initialSlide:11
        };
        return (<React.Fragment>
            {this.props.type == 'league' ? <div id="leagueResults" className="tabContent">
                <div className="tournament-content">
                    <div className="monthCarousel mb-20">
                        <Slider ref={slider => (this.slider = slider)} {...settings}>
                            {
                                this.props.carouselData.map((item) => {
                                    var formatedItem = formatFixtureResultsDate(item.fullDate, 'carousel')
                                    return <div className="month-list">
                                        <div className={(this.props.selectedCarouselItem.id === item.id ? "active" : "")}
                                            onClick={this.selectItem.bind(this, item)}><div>{this.props.staticKeyWords[formatedItem.month.toLowerCase()]}</div>
                                            <div>{formatedItem.year}</div></div>
                                    </div>
                                })
                            }
                        </Slider>


                    </div>

                    <div className="selectedMonthHeading">{this.props.selectedCarouselItem.month?this.props.staticKeyWords[fullMonths[this.props.selectedCarouselItem.month - 1].toLowerCase()]:""} {this.props.selectedCarouselItem.year}
                        <div className="sectionFilter">
                            <div className="form-group form-inline">
                                {/* <div className="form-label">Season</div> */}
                                <div className="form-control">
                                    <select onChange={this.leagueSeasonChanged.bind(this)}>
                                        {
                                            this.props.seasons.map((item)=>{
                                                return <option value={JSON.stringify(item)} >{item.seasonName}</option>
                                            })
                                        }
                                        
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.data.hasOwnProperty('info') && this.props.data.info.map((item) => {
                            var dateFormat = formatFixtureResultsDate(item.kickOff, 'maindata');
                            return <table className="tableDefault">
                                <thead>
                                    <tr>
                                        <th>{this.props.staticKeyWords[dateFormat.weekDay.toLowerCase()]+" "+dateFormat.date+" "+this.props.staticKeyWords[dateFormat.month.toLowerCase()]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        item.events.map((event) => {
                                            return <tr>
                                                <td>
                                                    <div className="fixture-info">
                                                        <div className="team-info">
                                                            <div className="text-right truncate">{event.homeTeamName}</div>
                                                        </div>
                                                        <div className="kickoff score">{event.homeTeamGoals} - {event.awayTeamGoals}</div>
                                                        <div className="team-info">
                                                            <div className="text-left truncate">{event.awayTeamName}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }

                                </tbody>
                            </table>
                        })
                    }
                    
                        <Loading isLoader={this.props.loader} isNoData={!this.props.loader && (Object.keys(this.props.data).length ===0 || !this.props.data.hasOwnProperty("info"))} temporarilyNoData={this.props.staticKeyWords.temporarilyNoData}/>
                    
                </div>
            </div> :
                <div id="leagueResults" className="tabContent">
                    <div className="tournament-content">
                        <div className="sectionHeading">
                            <div></div>
                            <div className="sectionFilter">
                                <div className="form-group form-inline">
                                    {/*<div className="form-label">Season</div>*/}
                                    <div className="form-control">
                                        <select onChange={this.seasonChanged.bind(this)}>
                                            {this.props.fixtureFilters.hasOwnProperty('Season') && this.props.fixtureFilters.Season.map((season, seasonIndex) => {
                                                return <option key={seasonIndex} value={season.id}>{season.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="tableDefault fixtureResults">
                            <thead>
                                <tr>
                                    <th>{this.props.staticKeyWords.league}
                                <div className="form-group">
                                            <div className="form-control">
                                                <select onChange={this.leagueChanged.bind(this)}>
                                                    {this.props.fixtureFilters.hasOwnProperty('Team_League') && this.props.fixtureFilters.Team_League.map((league, leagueIndex) => {
                                                        return <option key={leagueIndex} value={league.id}>{league.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </th>
                                    <th>{this.props.staticKeyWords.date}</th>
                                    <th>{this.props.staticKeyWords.ha}
                                <div className="form-group">
                                            <div className="form-control">
                                                <select onChange={this.optionsChanged.bind(this)}>
                                                    {this.props.fixtureFilters.hasOwnProperty('Options') && this.props.fixtureFilters.Options.map((option, optionIndex) => {
                                                        return <option key={optionIndex} value={option.id}>{option.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </th>
                                    <th>{this.props.staticKeyWords.against}</th>
                                    <th>{this.props.staticKeyWords.wbyl}</th>
                                    <th>{this.props.staticKeyWords.scoreHt}</th>
                                    <th className="w-75">{this.props.staticKeyWords.hHAD}
                                <div className="form-group">
                                            <div className="form-control">
                                                <select onChange={this.hhadChanged.bind(this)}>
                                                    {this.props.fixtureFilters.hasOwnProperty('Team_HHAD') && this.props.fixtureFilters.Team_HHAD.map((hhad, hhadIndex) => {
                                                        return <option key={hhadIndex} value={hhad.id}>{hhad.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </th>
                                    <th>{this.props.staticKeyWords.hdc}
                                <div className="form-group">
                                            <div className="form-control">
                                                <select onChange={this.hdcChanged.bind(this)}>
                                                    {this.props.fixtureFilters.hasOwnProperty('Team_HDC') && this.props.fixtureFilters.Team_HDC.map((hdc, hdcIndex) => {
                                                        return <option key={hdcIndex} value={hdc.id}>{hdc.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="w-75">{this.props.staticKeyWords.corners}
                                <div className="form-group">
                                            <div className="form-control">
                                                <select onChange={this.cornersChanged.bind(this)}>
                                                    {this.props.fixtureFilters.hasOwnProperty('Team_Corner') && this.props.fixtureFilters.Team_Corner.map((corner, cornerIndex) => {
                                                        return <option key={cornerIndex} value={corner.id}>{corner.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(this.props.data.hasOwnProperty('events') && this.props.data.events.length) ? this.props.data.events.map((event, eventIndex) => {
                                    return <tr>
                                        <td>
                                            <div>{event.competitionName ? event.competitionName : '-'}</div>
                                        </td>
                                        <td>
                                            <div>{event.kickOff ? (formatDate(event.kickOff, "banner").date) : '-'}</div>
                                        </td>
                                        <td>
                                            <div>{event.homeOrAway ? event.homeOrAway : '-'}</div>
                                        </td>
                                        <td>
                                            <div>{event.teamAganist ? event.teamAganist : '-'}</div>
                                        </td>
                                        <td>
                                            <div>{event.winOrLoss ? event.winOrLoss : '-'}</div>
                                        </td>
                                        <td>
                                            <div>{event.result ? event.result : '-'}</div>
                                        </td>
                                        <td>
                                            <div className="textIcon"><span>{event.hHad}</span><span className={event.hHadWinOrLoss ? (event.hHadWinOrLoss.toLowerCase() == 'w' ? 'status win' : event.hHadWinOrLoss.toLowerCase() == 'l' ? 'status loss' : 'status draw') : ''}>{event.hHadWinOrLoss ? event.hHadWinOrLoss : '-'}</span></div>
                                        </td>
                                        <td>
                                            <div className="textIcon"><span>{event.hdc}</span><span className={event.hdcWinOrLoss ? (event.hdcWinOrLoss.toLowerCase() == 'w' ? 'status win' : event.hdcWinOrLoss.toLowerCase() == 'l' ? 'status loss' : 'status draw') : ''}>{event.hdcWinOrLoss ? event.hdcWinOrLoss : '-'}</span></div>
                                        </td>
                                        <td>
                                            <div className="textIcon"><span>{event.totalCorners}</span><span className={event.cornersHilo ? (event.cornersHilo.toLowerCase() == 'high' ? 'status win' : event.cornersHilo.toLowerCase() == 'low' ? 'status loss' : '') : ''}>{event.cornersHilo ? (event.cornersHilo.toLowerCase() == 'high' ? 'H' : 'L') : '-'}</span></div>
                                        </td>
                                    </tr>
                                }) :
                                    <tr>
                                        <td colspan="9">
                                            <Loading isLoader={this.props.loader} isNoData={(!this.props.loader && (this.props.data.hasOwnProperty('events') ? !this.props.data.events.length : true))} />
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </React.Fragment>
        );
    }


}


function mapStateToProps(state) {
    return {
        data: state.fixturesresults.data,
        carouselData: state.fixturesresults.carouselData, 
        selectedCarouselItem: state.fixturesresults.selectedCarouselItem,
        fixtureFilters: state.fixturesresults.fixtureFilters,
        languageId: state.app.languageId,
        loader: state.app.loader,
        fixturesresults: state.fixturesresults,
        staticKeyWords: state.app.staticKeyWords,
        seasons:state.fixturesresults.seasons
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: bindActionCreators({
        }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FixturesResults);
