import React, { Component } from 'react';
import Highcharts from '../../sharedfiles/customhighcharts';
import { connect } from 'react-redux';

class HiLoGraphsDual extends Component {
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        console.log("hilo graph dual==",this.props.data)
        return (
            <>
                <div class="dualHilo-graph">
                    <div class="hiLoGraph">
                        <div class="hiLoGraph-header">
                            <div class="mainTitle medium">{this.props.title}</div>
        <div class="mainTitle home-text">{!this.props.hideTeamNames?this.props.homeTeamName:""} {this.props.showSubTitle?<small>(Last 8 Home Results)</small>:""}</div>
                            <div></div>
                        </div>
                        <div class="barGraph">
                            <div class="barsContent">
                                {
                                    this.props.data && this.props.data.map((item) => {
                                        return <div class="hiLoBar" style={{ "--red": item.homeTeamHigh, "--blue": item.homeTeamLow }}>
                                            <span class="awayBar">{item.homeTeamHigh>0?item.homeTeamHigh:""}</span>
                                            <span class="homeBar">{item.homeTeamLow>0?item.homeTeamLow:""}</span>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="line-title middleLine">{!this.props.hideLine?this.props.staticKeyWords.line:""}</div>
                        <div class="hiLoGraph-body">
                            <div class="lineContent">
                                {
                                    this.props.data.map((item) => {
                                        return <span>{item.line}</span>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div class="hiLoGraph">
                        <div class="hiLoGraph-header">
                            <div></div>
                            <div class="mainTitle away-text">{!this.props.hideTeamNames?this.props.awayTeamName:""} {this.props.showSubTitle?<small>(Last 8 Away Results)</small>:""}</div>
                            <div class="legend-info">{!this.props.hideLegend?<><div class="homeLegend">{this.props.staticKeyWords.highIcon}</div> <div class="awayLegend">{this.props.staticKeyWords.lowIcon}</div></>:""}</div>
                        </div>
                        <div class="barGraph">
                            <div class="barsContent">
                                {
                                    this.props.data.map((item) => {
                                        return <div class="hiLoBar" style={{ "--red": item.awayTeamHigh, "--blue": item.awayTeamLow }}>
                                                <span class="awayBar">{item.awayTeamHigh>0?item.awayTeamHigh:""}</span>
                                                <span class="homeBar">{item.awayTeamLow>0?item.awayTeamLow:""}</span>
                                            </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }


}
function mapStateToProps(state) {
    return {
        staticKeyWords:state.app.staticKeyWords,
    };
  }
  export default connect(mapStateToProps)(HiLoGraphsDual);
  //export default HiLoGraphsDual;
