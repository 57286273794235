import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Route} from 'react-router-dom';

import { selectTab } from './HToHTabSlice';
import HADHandicapTab from '../hadhandicaptab/HADHandicapTab';
import HiloCorners from '../hilocornerstab/HiloCornersTab';
import  HTOHOutput from '../htohoutput/htohOutput';

class HToHTab extends Component{
    componentWillMount(){
        this.props.dispatch(selectTab('had'))
    }
    componentDidMount(){
       
    }
    componentDidUpdate(prevProps, prevState){
        
    }
    render(){
        return <div className="tabSection">
                    {window.location.pathname.split("/").includes('htohoutput') ||  this.props.hidetabs?'':
                    <ul className="tabsList" data-persist="true">
                        <li className={"tabLinks"+(this.props.selectedTab==='had'?' active':'')} onClick={()=>this.props.dispatch(selectTab('had'))} id="defaultOpen">
                            <a >{this.props.staticKeyWords.hadHandicap}</a></li>
                        <li className={"tabLinks"+(this.props.selectedTab==='hilo'?' active':'')} onClick={()=>this.props.dispatch(selectTab('hilo'))}>
                            <a >{this.props.staticKeyWords.hiLoCorners}</a></li>
                    </ul>}
                    <div className="tabsContent">
                    {window.location.pathname.split("/").includes('htohoutput')?
                        <Route render={(props)=><HTOHOutput {...props} lastEncountersOptions={this.props.lastEncountersOptions} />}/>:
                        <React.Fragment>
                        {this.props.selectedTab==='had'?<Route render={(props)=><HADHandicapTab {...props} lastEncountersOptions={this.props.lastEncountersOptions} isBasic={this.props.isBasic} hideOptions={this.props.hideOptions}/>}/>:
                        <Route render={(props)=><HiloCorners {...props} lastEncountersOptions={this.props.lastEncountersOptions} isBasic={this.props.isBasic}/>}/>}
                        </React.Fragment>
                    }
                    </div>
                    {/*<HADHandicapTab lastEncountersOptions={this.props.lastEncountersOptions}/><HiloCorners lastEncountersOptions={this.props.lastEncountersOptions}/>*/}
                </div>

    }
    
}
function mapStateToProps(state) {
    return {
        selectedTab: state.htohtab.selectedTab,
        lastEncountersOptions:state.matchcenter.lastEncountersOptions,
        staticKeyWords: state.app.staticKeyWords
    };
  }
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
      actions: bindActionCreators({selectTab
      }, dispatch)
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(HToHTab);
