import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
// import { selectTab } from './RecentFormHandicapSlice';
import RecentformTable from '../recentformtable/RecentformTable';
import HaFuGraph from '../hafugraph/HaFuGraph';
//import TableGraph from '../tablegraph/TableGraph';

class RecentFormTab extends Component{
    componentDidMount(){
        
    }
    componentDidUpdate(prevProps, prevState){
        
    }
    render(){
        let hadData=[]
        if(this.props.hdcHad && this.props.hdcHad.length>0){
            for(var item of this.props.hdcHad){
                hadData.push(
                    {
                        outcomeName:item.result,
                        homeGoals:item.homeGoals,
                        awayGoals:item.awayGoals
                    }
                )
            }
        }
        return <div style={{"display": "block"}} id="Results" class="tabContent">
                <RecentformTable/>
                {this.props.isAdvancedMode && <div class="graphSection">
                    <div className="sectionHeading">
                        <div class="sectionTitle">{this.props.staticKeyWords.handicap}</div>
                    </div>
                    
                    <div class="graphContainer">
                        <div class="graphContent">
                        <div>
                            {/*<TableGraph data={this.props.hdc}
                            homeTeamName={this.props.homeTeamName}
                            awayTeamName={this.props.awayTeamName}/>*/}
                            <HaFuGraph data={this.props.hdc} maxValue={3} loadin="rfhdc" title={this.props.staticKeyWords.handicap} graphHeight="300px" graphWidth="650px"/>
                        </div>
                        <div><HaFuGraph data={hadData} maxValue={6} loadin="rfhdchad" title={this.props.staticKeyWords.handicapHAD} graphHeight="300px" graphWidth="650px"/></div>
                        </div>
                    </div>
                </div>}


            </div>

    }
    
}
function mapStateToProps(state) {
    return {
        hdcHad: state.recentfromtab.hdcHad,
        hdc: state.recentfromtab.hdc,
        homeTeamName: state.recentfromtab.homeTeamName,
        awayTeamName: state.recentfromtab.awayTeamName,
        staticKeyWords: state.app.staticKeyWords
    };
  }
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
      actions: bindActionCreators({
      }, dispatch)
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(RecentFormTab);
