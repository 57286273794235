import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, NavLink} from 'react-router-dom';
import { setTabData, setTabInfo, getSeasonslist, setPlayerInfo } from '../PlayerAnalysisSlice';
import Loading from '../../loading/Loading';
import { setLoader } from '../../../AppSlice';

class PlayerAnalysisTable extends Component {
    componentWillMount(){
    this.props.dispatch(setPlayerInfo([]));
    this.props.dispatch(setLoader(true));
    // this.props.dispatch(getSeasonslist({
    //     mainTab:this.props.match.params.homeaway,
    //     teamId:this.props.match.params.teamId,
    //     subTab:'had',
    //     seasonId:'1'
    //   },this.props.history))
    this.props.dispatch(setTabInfo({
        mainTab:this.props.match.params.homeaway,
        teamId:this.props.match.params.teamId,
        subTab:'had',
        seasonId:(this.props.allSeasons && this.props.allSeasons.length)?this.props.allSeasons[0].id:'1'
      },this.props.history))

    this.props.dispatch(setTabData({
        mainTab:this.props.match.params.homeaway,
        teamId:this.props.match.params.teamId,
        subTab:'had',
        seasonId:(this.props.allSeasons && this.props.allSeasons.length)?this.props.allSeasons[0].id:'1'
      },this.props.history,"",this.props.languageId))
    }
    componentWillReceiveProps(nextProps){
      if(this.props.bannerData!=nextProps.bannerData){
        this.props.dispatch(setTabData({
          mainTab:this.props.match.params.homeaway,
          teamId:this.props.match.params.teamId,
          subTab:'had',
          seasonId:(this.props.allSeasons && this.props.allSeasons.length)?this.props.allSeasons[0].id:'1'
        },this.props.history,"",this.props.languageId))
      }
    }
    render() {
        return (
               <div className="tabsContent">
                  <div id="Standings" className="tabContent">
                      <div className="tabTable-content">
                          <div className="tableHeader-home">
                              <div className="teamSelected">{this.props.tabInfo.mainTab=='home'?this.props.homeTeamName:this.props.awayTeamName}</div>
                          </div>
                          <table className="tableDefault playerAnalysis">
                              <thead>
                                <tr>
                                  {(this.props.tabInfo.subTab=='had'?this.props.hadColumns:this.props.hiloColumns).map((column,index)=>{
                                    if(column.hasOwnProperty('isRow')){
                                      return <React.Fragment key={index}>
                                          {(column.isRow)?
                                              <th className={column.className} rowSpan={column.spanValue}>{column.column}</th>:
                                              <th className={column.className} colSpan={column.spanValue}>{column.column}</th>
                                          }
                                      </React.Fragment>  
                                    }         
                                  })
                                }
                                </tr>
                                <tr>
                                  {
                                    (this.props.tabInfo.subTab=='had'?this.props.hadColumns:this.props.hiloColumns).map((column,index)=>{
                                        return <React.Fragment key={index}>
                                            {(!column.hasOwnProperty('isRow')) &&
                                                <th className={column.className}>{column.column}</th>
                                            }
                                        </React.Fragment>           
                                    })
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.playerData.length?this.props.playerData.map((rowData,rowIndex)=>{
                                  return <tr key={rowIndex}>
                                    {(this.props.tabInfo.subTab=='had'?this.props.hadColumns:this.props.hiloColumns).map((column,index)=>{
                                      if(column.isDisplay){
                                      return <td key={index}>
                                                <div>{rowData[column['key']]}</div>
                                             </td>
                                           }
                                    })
                                    }
                                  </tr>
                                }):
                                <tr>
                                  <td colspan={this.props.tabInfo.subTab=='had'?this.props.hadColumns.length:this.props.hiloColumns.length}>
                                    <Loading isLoader={this.props.loader} isNoData={!this.props.loader && !this.props.playerData.length}/>
                                  </td>
                                </tr>
                                }
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>    
        );
    }
}
function mapStateToProps(state) {
  return {
      hadColumns:state.playeranalysis.hadColumns,
      hiloColumns:state.playeranalysis.hiloColumns,
      tabInfo:state.playeranalysis.tabInfo,
      homeTeamName:state.match.bannerData.homeTeamName,
      awayTeamName:state.match.bannerData.awayTeamName,
      playerData:state.playeranalysis.playerData,
      loader:state.app.loader,
      bannerData:state.match.bannerData,
      languageId:state.app.languageId,
      allSeasons:state.match.allSeasons

  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({
      setTabData
    }, dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PlayerAnalysisTable);