import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, NavLink} from 'react-router-dom';
import PlayerAnalysisTable from './playeranalysistable/PlayerAnalysisTable';
import { setTabInfo, setTabData, setTableHeaders } from './PlayerAnalysisSlice';

class PlayerAnalysis extends Component {
      componentWillMount(){
        if(this.props.staticKeyWords && Object.keys(this.props.staticKeyWords).length>0){
          this.props.dispatch(setTableHeaders(this.props.staticKeyWords))
        }
      }
      componentWillReceiveProps(nextProps) {
        if (this.props.tabInfo.teamId && (this.props.tabInfo.teamId != nextProps.tabInfo.teamId)) {
            this.props.dispatch(setTabData(nextProps.tabInfo,this.props.history,"",this.props.languageId));
        }
        if(this.props.staticKeyWords !== nextProps.staticKeyWords){
          this.props.dispatch(setTableHeaders(nextProps.staticKeyWords))
        }
      }

      seasonChanged(event){
        this.props.dispatch(setTabData({
                                  mainTab:this.props.tabInfo.mainTab,
                                  teamId:this.props.tabInfo.teamId,
                                  subTab:this.props.tabInfo.subTab,
                                  seasonId:event.target.value
                                },this.props.history,"",this.props.languageId))
      }

    render() {
        return (
              <div className="pageContent">
                        {/*
                        <div className="teamSelection">
                            <div className="teamSelection-heading">{this.props.staticKeyWords.team}</div>
                        </div>
                        */}
                        <div className="teamSelection-buttons">
                            <div>
                              <button className={"btn "+(this.props.tabInfo.mainTab=='home'?'active':'')} onClick={()=> this.props.dispatch(setTabData({
                                  mainTab:'home',
                                  teamId:this.props.homeTeamId,
                                  subTab:this.props.tabInfo.subTab,
                                  seasonId:this.props.tabInfo.seasonId
                                },this.props.history,'noneedtocall',this.props.languageId))}>{this.props.homeTeamName}</button>
                            </div>
                            <div>
                              <button className={"btn "+(this.props.tabInfo.mainTab=='away'?'active':'')} onClick={()=> this.props.dispatch(setTabData({
                                  mainTab:'away',
                                  teamId:this.props.awayTeamId,
                                  subTab:this.props.tabInfo.subTab,
                                  seasonId:this.props.tabInfo.seasonId
                                },this.props.history,'noneedtocall',this.props.languageId))}>{this.props.awayTeamName}</button>
                            </div>
                        </div>
                        <div className="tabSection">
                            <div className="form-group form-inline dropdown-position">
                                {/*<div className="form-label">Season</div>*/}
                                <div className="form-control">
                                    <select value={this.props.tabInfo.seasonId} onChange={this.seasonChanged.bind(this)}>
                                    {this.props.allSeasons.map((season,seasonIndex)=>{
                                        return <option key={seasonIndex} value={season.id}>{season.name}</option>
                                    })}
                                    </select>
                                </div>
                            </div>
                            <ul className="tabsList" data-persist="true">
                                <li className={"tabLinks "+(this.props.tabInfo.subTab=='had'?'active':'')} onClick={()=> this.props.dispatch(setTabData({
                                  mainTab:this.props.tabInfo.mainTab,
                                  teamId:this.props.tabInfo.teamId,
                                  subTab:'had',
                                  seasonId:this.props.tabInfo.seasonId
                                },this.props.history,"",this.props.languageId))} id="defaultOpen">
                                    <a >{this.props.staticKeyWords.wbyl}</a></li>
                                <li className={"tabLinks "+(this.props.tabInfo.subTab=='hilo'?'active':'')} onClick={()=> this.props.dispatch(setTabData({
                                  mainTab:this.props.tabInfo.mainTab, 
                                  teamId:this.props.tabInfo.teamId,
                                  subTab:'hilo',
                                  seasonId:this.props.tabInfo.seasonId 
                                },this.props.history,"",this.props.languageId))}> 
                                    <a >{this.props.staticKeyWords.goals}</a>
                                </li>
                            </ul>
                            <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/playeranalysis/:homeaway/:teamId" render={(props)=><PlayerAnalysisTable {...props}/>}/>
                        </div>
                    </div>     
        );
    }
}
function mapStateToProps(state) {
  return {
      homeTeamName:state.match.bannerData.homeTeamName,
      awayTeamName:state.match.bannerData.awayTeamName,
      homeTeamId:state.match.bannerData.homeTeamId,
      awayTeamId:state.match.bannerData.awayTeamId,
      tabInfo:state.playeranalysis.tabInfo,
      allSeasons:state.match.allSeasons,
      staticKeyWords:state.app.staticKeyWords,
      languageId:state.app.languageId
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({
      setTabInfo
    }, dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PlayerAnalysis);