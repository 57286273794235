import React, { Component } from 'react';

class PerGameGraph extends Component {
    render() {
        return (
            <div class="goalsperGame-graph">
                <div class="goalsperGame-content">
                <div class="homeGoals-data">
                <div class={"homeGoals-circle "+(Number(this.props.home)>Number(this.props.away)?"circle":"")}>{this.props.home}</div>
                <div class="home-text">{this.props.homeTeamName}</div>
                </div>
                <div class="graphTitle" dangerouslySetInnerHTML={{__html: this.props.title}}></div>
                <div class="awayGoals-data">
                <div class={"awayGoals-circle "+(Number(this.props.away)>Number(this.props.home)?"circle":"")}>{this.props.away}</div>
                <div class="away-text">{this.props.awayTeamName}</div>
                </div>
                    </div>
                <div class="goalsperGame-teamNames">
                    
                   
                </div>
            </div>
        );
    }


}

export default PerGameGraph;
