import React,{Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { getFiltersData,setFilter, setTab,getStandingsData, setTableHeaders } from './TournamentStandingsSlice';
import StandingsTable from '../standingstable/StandingsTable';
import TeamsGroupTable from '../teamsgrouptable/teamsGroupTable';

class TournamentStandings extends Component{
    componentWillMount(){
        console.log("path ===",this.props.match.path.includes('league'));
        this.props.dispatch(getFiltersData(1,this.props.languageId));
        if(this.props.match && this.props.match.params){
            let params = this.getParams(this.props);
            if(Object.keys(params).length>0 ){
                this.props.dispatch(getStandingsData(params,this.props.match.params.leagueType,this.props.languageId));
            }
        }
        if(this.props.staticKeyWords && Object.keys(this.props.staticKeyWords).length>0){
            this.props.dispatch(setTableHeaders(this.props.staticKeyWords))
        }
    }
    componentWillReceiveProps(nextProps) {
        if(this.props.staticKeyWords !== nextProps.staticKeyWords){
            this.props.dispatch(setTableHeaders(nextProps.staticKeyWords))
        }
      }
    getParams(props){
        if(props.type==='league'){
            if(props.match && props.match.params.leagueId && props.match.params.leagueId>0){
                return {optionId:props.selectedTab,
                        seasonId:props.selectedSeasonFilter,
                        isLeague:true,
                        compId:Number(props.match.params.leagueId),
                        teamId:""}
            }
        }else{
            return {optionId:props.selectedTab,
                seasonId:props.selectedSeasonFilter,
                isLeague:false,
                compId:Number(props.match.params.leagueId),
                teamId:Number(props.match.params.teamId)}
        }
       return {};
    }
    componentWillReceiveProps(nextProps) {
        console.log("next props==",nextProps);
        if(this.props.match && 
            this.props.match.params && JSON.stringify(this.props.match.params) !== JSON.stringify(nextProps.match.params)){
                if((window.location.pathname.includes('team') && 
                this.props.match.params.teamId !== nextProps.match.params.teamId)||
                ( window.location.pathname.includes('league') && 
                this.props.match.params.leagueId !== nextProps.match.params.leagueId)||(
                    this.props.match.path !==nextProps.match.path
                )){
                    this.props.dispatch(getFiltersData(1,this.props.languageId));
                    let params = this.getParams(nextProps);
                    this.props.dispatch(getStandingsData(params,this.props.match.params.leagueType,this.props.languageId));
                }
            
        }
    }
    componentDidMount(){
        
        
    }
    
    seasonChanged(event){
        this.props.dispatch(setFilter({"value":event.target.value}))
        if(this.props.match && this.props.match.params && this.props.match.params.leagueId && this.props.match.params.leagueId>0){
            this.props.dispatch(getFiltersData(1,this.props.languageId));
            let params = this.getParams(this.props);
            params.seasonId=event.target.value;
            this.props.dispatch(getStandingsData(params,this.props.match.params.leagueType,this.props.languageId));
        }
        // this.props.dispatch(getStandingsData({optionId:this.props.selectedTab,
        //                                       seasonId:event.target.value}));
        //this.props.dispatch(getFiltersData(this.props.selectedTable.tableId))
    }
    selectTab(tabId){
        this.props.dispatch(setTab(tabId));
        if(this.props.match && this.props.match.params && this.props.match.params.leagueId && Number(this.props.match.params.leagueId)>0){
            this.props.dispatch(getFiltersData(1,this.props.languageId));
            let params = this.getParams(this.props);
            params.optionId=tabId;
            this.props.dispatch(getStandingsData(params,this.props.match.params.leagueType,this.props.languageId));
        }
        // this.props.dispatch(getStandingsData({optionId:tabId,
        //     seasonId:this.props.selectedSeasonFilter}));
        //this.props.dispatch(getFiltersData(this.props.selectedTable.tableId))
        
    }
    render(){
        return (
        <React.Fragment>
        {((this.props.match.params.leagueType=='league') || (this.props.match.params.leagueType!='league' && this.props.type=='team'))?
        <div id="leagueStandings" className="tabContent">
            <div className="tournament-content">
                <div className="btn-group pt-0">
                    {this.props.optionsList.map((option)=>{
                        return <button className={"buttonAll"+(this.props.selectedTab===option.id?" active":"")} onClick={this.selectTab.bind(this,option.id)}>{option.name}</button>
                    })}
                </div>
                <div className="sectionHeading">
                    <div></div>
                    <div className="sectionFilter">
                        <div className="form-group form-inline">
                            {/* <div className="form-label">Season</div> */}
                            <div className="form-control">
                                <select onChange={this.seasonChanged.bind(this)}>
                                    {
                                        this.props.seasonFilterData.map((item)=>{
                                            return <option value={item.id}>{item.name}</option>
                                        })
                                    }
                                    
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <Route render={(props)=> <StandingsTable {...props} standingColumns={this.props.standingColumns} 
                                standingsTableData={this.props.standingsData.hasOwnProperty('info') && this.props.standingsData.info}
                                isWhiteBG={true}
                                isRowClick={this.props.type==="league"?true:false} 
                                paramKey="teamId"
                                rowNavigation="team/[:id]/standings" isTournament={this.props.isTournament} type={this.props.type} teamId={this.props.match.params.teamId}/>}/>
               

            </div>
        </div>:
        (this.props.standingsData.hasOwnProperty('info'))?
        <Route render={(props)=> <TeamsGroupTable  {...props}
                        standingsTableData={this.props.standingsData.info}
                        screenfrom='standings'
                        rowNavigation="team/[:id]/standings"/>}/>:'' 
        }   
        </React.Fragment> 
        );
    }
    
        
}


function mapStateToProps(state) {
  return {
    optionsList: state.tournamentstandings.optionsList,
    seasonFilterData: state.tournamentstandings.seasonFilterData,
    selectedTab: state.tournamentstandings.selectedTab,
    selectedSeasonFilter: state.tournamentstandings.selectedSeasonFilter,
    standingsData: state.tournamentstandings.standingsData,
    standingColumns: state.tournamentstandings.standingColumns,
    staticKeyWords: state.app.staticKeyWords,
    languageId:state.app.languageId
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({
    }, dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(TournamentStandings);
