import React,{Component} from 'react';

class TableGraph extends Component{
    render(){
        console.log("tableGraph==",this.props.data)
        return (
            <table class="tableDefault tableForGraph">

                <thead>
                    <tr>
                        <th></th>
                        <th>FULL WIN</th>
                        <th>HALF WIN</th>
                        <th>DRAW</th>
                        <th>HALF LOSS</th>
                        <th>FULL LOSS</th>


                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="home-text">{this.props.homeTeamName}</div>
                        </td>
                        <td>
                            <div>{this.props.data.homeTeam.fullWin}</div>
                        </td>
                        <td>
                            <div>{this.props.data.homeTeam.halfWin}</div>
                        </td>
                        <td>
                            <div>{this.props.data.homeTeam.draw}</div>
                        </td>
                        <td>
                            <div>{this.props.data.homeTeam.halfLoss}</div>
                        </td>
                        <td>
                            <div>{this.props.data.homeTeam.fullLoss}</div>
                        </td>


                    </tr>

                    <tr>
                        <td>
                            <div class="away-text">{this.props.awayTeamName}</div>
                        </td>
                        <td>
                            <div>{this.props.data.awayTeam.fullWin}</div>
                        </td>
                        <td>
                            <div>{this.props.data.awayTeam.halfWin}</div>
                        </td>
                        <td>
                            <div>{this.props.data.awayTeam.draw}</div>
                        </td>
                        <td>
                            <div>{this.props.data.awayTeam.halfLoss}</div>
                        </td>
                        
                        <td>
                            <div>{this.props.data.awayTeam.fullLoss}</div>
                        </td>

                    </tr>
                

                    

                </tbody>
                </table>    
        );
    }
    
        
}

export default TableGraph;
