import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { getCategories, navigateToHtoHTab, selectTeam, setTeam_One, setTeam_Two } from './HtoHSearchSlice';
import { 
          // getCountryList, 
         // getCompetitionsList,
         // getEventList,
         setEventInfoCountry,
         setEventInfoCompetition,
         setEventInfoType,
         getTeamsList,
         loadSearchData,
         getEventInfo,
         setTeamNames } from './HtoHSearchSlice';
import ReactAutocomplete from 'react-autocomplete';
import { getTournamentCountries } from '../tournament/TournamentSlice';
import { getCompetitionsList, getEventList, setEventList} from '../../layouts/matchSlice';
import noImage from '../../assets/images/teams/no-img.png';
import Loading from '../loading/Loading';
import  HTOHOutput from '../htohoutput/htohOutput';

class HtoHSearch extends Component {
  componentWillMount(){
    this.props.dispatch(setTeam_One(''))
    this.props.dispatch(setTeam_Two(''))
    this.props.dispatch(setEventList([]))
    this.props.dispatch(setTeamNames({name:'',id:'',text:'teamone'}))
    this.props.dispatch(setTeamNames({name:'',id:'',text:'teamtwo'}))

    this.props.dispatch(getCategories(this.props.languageId))
  }
  render() {
    return (
      <section className="layout-content">
        <div className="mainContent container">
            <div className="mainLanding-page headToHeadSearch">
                <div className="contentBg">
                    <div className="headToHeadSearch-heading">{this.props.staticKeyWords.h2hTitle}</div>
                    <div className="headToHead-teamSearch">
                        <div className="headToHead-teamSearchGroup">
                            <div className="form-group">
                                <div className="form-label">{this.props.staticKeyWords.team} 1</div>
                                <div className="form-control">
                                    {/*<input placeholder="Enter team name" type="text"/>*/}
                                    <ReactAutocomplete
                                    getItemValue={(item) => item.teamName}
                                    items={this.props.teamOneList}
                                    renderItem={(item, isHighlighted) =>
                                        <div key={item.itemId} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                            {item.teamName}
                                        </div>
                                    }
                                    inputProps={{ placeholder: this.props.staticKeyWords.enterteamname }}
                                    value={this.props.teamOne.teamName}
                                    onChange={(e)=>this.props.dispatch(getTeamsList(e.target.value,'teamone',this.props.languageId))}
                                    onSelect={(teamName) => this.props.dispatch(loadSearchData(this.props.teamOneList,teamName,'teamone'))}
                                    />
                                </div>
                            </div>
                            <div className="team-vs">VS</div>
                            <div className="form-group">
                                <div className="form-label">{this.props.staticKeyWords.team} 2</div>
                                <div className="form-control">
                                    {/*<input placeholder="Enter team name" type="text"/>*/}
                                    <ReactAutocomplete
                                    getItemValue={(item) => item.teamName}
                                    items={this.props.teamTwoList}
                                    renderItem={(item, isHighlighted) =>
                                        <div key={item.itemId} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                            {item.teamName}
                                        </div>
                                    }
                                    inputProps={{ placeholder: this.props.staticKeyWords.enterteamname }}
                                    value={this.props.teamTwo.teamName}
                                    onChange={(e)=>this.props.dispatch(getTeamsList(e.target.value,'teamtwo',this.props.languageId))}
                                    onSelect={(teamName) => this.props.dispatch(loadSearchData(this.props.teamTwoList,teamName,'teamtwo'))}
                                    />
                                </div>
                            </div>
                        </div>
                        <button className={"btn "+(this.props.teamOne.teamId && this.props.teamTwo.teamId?"":'fadeout')} onClick={()=> this.props.dispatch(getEventInfo({homeTeam:this.props.teamOne,awayTeam:this.props.teamTwo},this.props.history,this.props.languageId))}>{this.props.staticKeyWords.search}</button>
                    </div>
                    <div className="orDivider">
                        {this.props.staticKeyWords.orselectbelow}
                    </div>
                    <div className="headToHead-teamFilter">
                        <div className="form-group">
                            <div className="form-label">{this.props.staticKeyWords.type}</div>
                            <div className="form-control">
                                <select onChange={(event)=> {this.props.dispatch(setEventInfoType(event.target.value));this.props.dispatch(getTournamentCountries(event.target.value==1?'club':'international',this.props.languageId,'search',event.target.value))}}>
                                  {this.props.listTypes.map((type,typeIndex)=>{
                                    return <option key={typeIndex} value={type.id}>{type.name}</option>
                                  })}
                                </select>
                            </div>
                        </div>
                            <div className="form-group">
                                <div className="form-label">{this.props.eventInfo.typeId=='1'?this.props.staticKeyWords.country:this.props.staticKeyWords.continent}</div>
                                <div className="form-control">
                                    <select value={this.props.eventInfo.countryId} onChange={(event)=> {this.props.dispatch(setEventInfoCountry(event.target.value));this.props.dispatch(getCompetitionsList(event.target.value,'','search',this.props.languageId,this.props.eventInfo.typeId,'',this.props))}}>
                                        {this.props.htohCountries.map((country,countryIndex)=>{
                                          return <option key={countryIndex} value={country.countryId}>{country.countryName}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            {(this.props.eventInfo.typeId=='1') && <div className="form-group">
                                <div className="form-label">{this.props.staticKeyWords.league}</div>
                                <div className="form-control">
                                    <select value={this.props.eventInfo.competitionId} onChange={(event)=> {this.props.dispatch(setEventInfoCompetition(event.target.value));this.props.dispatch(getEventList(event.target.value,'search',this.props.languageId,this.props.eventInfo.typeId,this.props.eventInfo.countryId,this.props))}}>
                                        {this.props.competitionsList && this.props.competitionsList.map((competition,competitionIndex)=>{
                                          return <option key={competitionIndex} value={competition.competitionId}>{competition.competitionName}</option>
                                        })}
                                    </select>
                                </div>
                            </div>}
                    </div>
                    {this.props.eventList.length>0?
                    <div className="headToHead-teamSearchSelection">
                        <ul className="">
                          {this.props.eventList && this.props.eventList.map((event,eventIndex)=>{
                            return <li className={((event.teamId==this.props.team_one.teamId) || (event.teamId==this.props.team_two.teamId))?'active':''} key={eventIndex} onClick={()=> this.props.dispatch(selectTeam(event,this.props.team_one,this.props.team_two,this.props.history,this.props.languageId))}>{/*<img src={noImage}/>*/}{event.teamName}</li>

                          })}                            
                        </ul>
                    </div>:
                    (!this.props.eventList.length)?
                    <Loading isLoader={this.props.loader} isNoData={!this.props.loader && !this.props.eventList.length>0}/>:''
                    }
                    {Object.keys(this.props.h2hLastEncounters).length>0?
                      <Route render={(props)=><HTOHOutput {...props} lastEncountersOptions={this.props.lastEncountersOptions} />}/>:''
                    }
                </div>
            </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
    return {
     listTypes:state.htohsearch.listTypes,
     countryList:state.tournament.countryList,
     competitionsList:state.match.competitionsList,
     eventList:state.match.eventList,
     eventInfo:state.htohsearch.eventInfo,
     teamOneList:state.htohsearch.teamOneList,
     teamTwoList:state.htohsearch.teamTwoList,
     teamOne:state.htohsearch.teamOne,
     teamTwo:state.htohsearch.teamTwo,
     htohCountries:state.tournament.htohCountries,
     team_one:state.htohsearch.team_one,
     team_two:state.htohsearch.team_two,
     staticKeyWords: state.app.staticKeyWords,
     languageId: state.app.languageId,
     loader: state.app.loader,
     lastEncountersOptions:state.match.lastEncountersOptions,
     h2hLastEncounters:state.htohoutput.h2hLastEncounters,
    };
  }
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: bindActionCreators({
      
        }, dispatch)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(HtoHSearch);

