import React,{Component} from 'react';
import { bindActionCreators } from "redux";

import LeicesterLogo from '../../assets/images/Leicester.png';
import AstonvillaLogo from '../../assets/images/astonvilla.png';
import {formatDate, addSuffix} from '../../sharedfiles/helper'; 
import { imageUrls } from '../../sharedfiles/EndpointConfig';


export function MatchBanner(props) {
        console.log("match Banner===",formatDate(props.data.kickOffTime, 'banner'));
        var koDate = formatDate(props.data.kickOffTime, 'banner');
        return (
            <div class="mainTeamBanner">
                <div class="gameDetails">
                    <div class="homeTeam" style={{"background-color": "#0C5483"}}>

                        <div class="homeTeam-logo">
                            <img className="teamLogo-img" src={imageUrls.team+""+props.data.homeTeamLogo+".png"} alt="logo"/>
                            {/*<span class={"teamLogo t-"+props.data.homeTeamLogo}></span>*/}
                        </div>
                        <div class="homeTeam-details">
                            <div class="teamName">{props.data.homeTeamName} </div>
                            <div class="teamPosition">({props.data.homeTeamPos?<>{props.data.homeTeamPos}<sup>{addSuffix(props.data.homeTeamPos,true)}</sup></>:""})</div>
                        </div>
                    </div>
                    
                    <div class="leagueDetails">
                            <div class="kickOff-details">
                                <div class="normalPosition">
                                    
                                    <div>{koDate && koDate.day?koDate.day:""} {props.data.matchNumber}</div>
                                    <div>{props.data.competitionName}</div>
                                    <div class="date">{koDate && koDate.date?koDate.date:""} <span class="time">{koDate && koDate.time?koDate.time:""}</span></div>
                                </div>
                            </div>
                            <div class="oddsDetails">
                                <div class="odds home">{props.data.homeOdds?props.data.homeOdds.toFixed(2):""}</div>
                                <div class="odds draw">{props.data.drawOdds?props.data.drawOdds.toFixed(2):""}</div>
                                <div class="odds away">{props.data.awayOdds?props.data.awayOdds.toFixed(2):""}</div>
                            </div>

                        </div>
                    
                    <div class="awayTeam" style={{"background-color": "#670633"}}>


                        <div class="awayTeam-details">
                            <div class="teamName">{props.data.awayTeamName} </div>
                            <div class="teamPosition">
                                ({props.data.awayTeamPos?<>{props.data.awayTeamPos}<sup>{addSuffix(props.data.awayTeamPos,true)}</sup></>:""})
                            </div>
                        </div>
                        <div class="awayTeam-logo">
                            {/*<img src={props.data.awayTeamLogo} alt="logo"/>*/}
                            {/*<img src={AstonvillaLogo} alt="logo"/>*/}
                           {/* <span class={"teamLogo t-"+props.data.awayTeamLogo}></span>*/}
                           <img className="teamLogo-img" src={imageUrls.team+""+props.data.awayTeamLogo+".png"} alt="logo"/>
                        </div>

                    </div>
                </div>
            </div>
                    
        );
    
    
        
}
