import React,{Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import LeicesterLogo from '../../assets/images/players/James Justin.png';
import countryLogo from '../../assets/images/teams/arsenal.png';
import noImage from '../../assets/images/teams/UnitedKingdom.png';
import { imageUrls } from '../../sharedfiles/EndpointConfig';

class TeamsGroupTable extends Component{
    rowNavigation(link){
        this.props.history.push(link);
    }
    render(){
        return <React.Fragment>
        {(this.props.screenfrom=='standings' && Object.keys(this.props.standingsTableData).length>0)?
            <div className="standingTable-container">
                {Object.keys(this.props.standingsTableData).length>0 && Object.keys(this.props.standingsTableData).map((key)=>{
                    return <div className="standingTable-group"> 
                    <table className="tableDefault standingsTable">
                        <thead>
                            <tr>
                                <th><div className="groupHeading">{key}</div></th>
                                <th className="w-100px">P</th>
                                <th className="w-100px">Pts</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.standingsTableData[key].length>0 && this.props.standingsTableData[key].map((item,itemIndex)=>{
                                return <tr>
                                    <td onClick={this.rowNavigation.bind(this,this.props.rowNavigation.replace("[:id]",item.teamId))}>
                                        {/*<span><img src={item.imageURL} width="30px" alt="teams"/></span>*/}
                                        <span>{item.teamName}</span>
                                    </td>
                                    <td>
                                        <div>{item.teamPlayed}</div>
                                    </td>
                                    <td>
                                        <div>{item.teamPoints}</div>
                                    </td>
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                </div>
                })
                }                    
            </div>:
            (this.props.screenfrom=='standings' && !Object.keys(this.props.standingsTableData).length)?
            <Loading isLoader={this.props.loader} isNoData={!this.props.loader && !Object.keys(this.props.standingsTableData).length>0}/>:''
        }
        {(this.props.screenfrom=='topscorer' && this.props.standingsTableData.length>0)?
            <div className="topScorer-container">
                <div className="headerDetails"> 
                    <div className="haed-playerRank">{this.props.staticKeyWords.tournamentRank}</div>
                    <div className="haed-playerInfo">{this.props.staticKeyWords.player}</div>
                    <div className="haed-goalsNumber">{this.props.staticKeyWords.goals}</div>
                    <div className="haed-playerHkjcno">{this.props.staticKeyWords.hKJCNo}</div>
                </div>
                {this.props.standingsTableData && this.props.standingsTableData.map((player,playerIndex)=>{
                    if(playerIndex<=9){
                        return <div className="topScore-playerdetails">
                                <div className="playerRank">{player.playerRank}</div>
                                <div className="playerIcon">
                                    <div className="playerIcon-position"><img src={imageUrls.player+""+player.playerImageUrl+".png"} alt="country"/></div>
                                </div>
                                <div className="playerCountry"><img className="countryLogo-img" src={imageUrls.country+""+player.countryImgUrl+".png"} alt="country"/></div>
                                <div className="playerInfo">
                                    <span>{player.playerName}</span>
                                    <span className="text-blue">{player.playerClub}</span>
                                </div>
                                <div className="playerGoals"> 
                                    <div className="goalsNumber">{player.playerGoals}</div>
                                    <div className="crossline"></div>
                                </div>
                                <div className="playerHkjcno">{player.playerHkjcNo}</div>
                            </div>
                    }
                })}
            </div>:
            (this.props.screenfrom=='topscorer' && !this.props.standingsTableData.length)?
            <Loading isLoader={this.props.loader} isNoData={!this.props.loader && !this.props.standingsTableData.length>0}/>:''
        }
        </React.Fragment>
    }
    
}
function mapStateToProps(state) {
    return {
        loader: state.app.loader,
        staticKeyWords: state.app.staticKeyWords,
    };
  }
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: bindActionCreators({}, dispatch)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(TeamsGroupTable);
