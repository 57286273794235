import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { selectTab, getGraphData, loadData, resetTableData } from './RecentFormTabSlice';
import RecentFormHAD from '../recentformhad/RecentFormHAD';
import RecentFormHandicap from '../recentformhandicap/RecentFormHandicap';
import RecentFormHiLo from '../recentformhilo/RecentFormHiLo';
import RecentFormCorners from '../recentformcorners/RecentFormCorners';

class RecentFormTab extends Component{
    componentWillMount(){
        this.props.dispatch(selectTab('had')); 
        
    }
    componentDidMount(){
        if(this.props.bannerData && Object.keys(this.props.bannerData).length>0){
            this.props.dispatch(loadData('had','','',this.props.bannerData.homeTeamId,this.props.bannerData.awayTeamId,this.props.languageId,{}));
        }
    }
    componentWillReceiveProps(nextProps){
        if((this.props.bannerData != nextProps.bannerData) && window.location.pathname.split("/").includes('recentform')){
             this.props.dispatch(loadData('had','','',nextProps.bannerData.homeTeamId,nextProps.bannerData.awayTeamId,this.props.languageId,{}));
        }
    }
    componentDidUpdate(prevProps, prevState){
        
    }
    componentWillUnmount(){
        debugger;
        this.props.dispatch(resetTableData());
    }
    render(){
        return <div className="tabSection">
                    <ul class="tabsList" data-persist="true">
                        <li class={"tabLinks"+(this.props.selectedTab==="had"?" active":"")}  id="defaultOpen" onClick={()=>this.props.dispatch(loadData('had','','',this.props.homeTeamId,this.props.awayTeamId,this.props.languageId,{}))}>
                            <a >{this.props.staticKeyWords.had}</a></li>
                        <li class={"tabLinks"+(this.props.selectedTab==="handicap"?" active":"")} onClick={()=>this.props.dispatch(loadData('handicap','','',this.props.homeTeamId,this.props.awayTeamId,this.props.languageId,{}))}><a >
                            {this.props.staticKeyWords.handicaps}</a></li>
                        <li class={"tabLinks"+(this.props.selectedTab==="hilo"?" active":"")}  id="defaultOpen" onClick={()=>this.props.dispatch(loadData('hilo','','',this.props.homeTeamId,this.props.awayTeamId,this.props.languageId,{}))}>
                            <a >{this.props.staticKeyWords.hiLo}</a></li>
                        <li class={"tabLinks"+(this.props.selectedTab==="corners"?" active":"")} onClick={()=>this.props.dispatch(loadData('corners','','',this.props.homeTeamId,this.props.awayTeamId,this.props.languageId,{}))}><a >
                        {this.props.staticKeyWords.corners}</a></li>
                    </ul>
                    <div class="tabsContent">
                        {this.props.selectedTab ==="had"?<RecentFormHAD isAdvancedMode={this.props.isAdvancedMode}/>:
                    
                        this.props.selectedTab ==="handicap"?<RecentFormHandicap isAdvancedMode={this.props.isAdvancedMode}/>:
                        this.props.selectedTab ==="hilo"?<RecentFormHiLo isAdvancedMode={this.props.isAdvancedMode}/>:
                        <RecentFormCorners isAdvancedMode={this.props.isAdvancedMode}/>}


                    </div>
                </div>

    }
    
}
function mapStateToProps(state) {
    return {
        selectedTab: state.recentfromtab.selectedTab,
        homeTeamData: state.recentformhad.homeTeamData,
        awayTeamData: state.recentformhad.awayTeamData,
        homeTeamName: state.recentformhad.homeTeamName,
        awayTeamName: state.recentformhad.awayTeamName,
        bannerData: state.match.bannerData,
        homeTeamId: state.match.bannerData.homeTeamId,
        awayTeamId: state.match.bannerData.awayTeamId,
        staticKeyWords: state.app.staticKeyWords,
        languageId: state.app.languageId,
        defaultHomeOptionId:state.recentfromtab.defaultHomeOptionId,
        defaultAwayOptionId:state.recentfromtab.defaultAwayOptionId,
    };
  }
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
      actions: bindActionCreators({selectTab
      }, dispatch)
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(RecentFormTab);
