import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Route, Switch} from 'react-router-dom';
import  MatchCenter  from '../components/matchcenter/MatchCenter';
import { MatchBanner } from '../components/matchbanner/MatchBanner';
import {setBanner,
  getBanner, 
  highlightTab,
  navigateTo,
  getCountryList,
  getToggleMode,
  getSeasonslist} from './matchSlice';
//import HToHTab from '../components/htohtab/HToHTab';
import SeasonalStats from '../components/seasonalstats/SeasonalStats';
import PlayerAnalysis from '../components/playeranalysis/PlayerAnalysis';
import { setEventId } from '../AppSlice';
//import { setTabData,getTeamData } from '../components/playeranalysis/PlayerAnalysisSlice';
import Summary  from '../components/summary/Summary';
import BMRecentForms  from '../components/bmrecentforms/BMRecentForms';
import BMStatistics  from '../components/bmstatistics/BMStatistics';
import BMHead2Head from '../components/bmhead2head/BMHead2Head';

class Match extends Component {
  componentWillMount(){
      this.props.dispatch(getSeasonslist(this.props.languageId))
      this.props.dispatch(setEventId({
        countryId:this.props.match.params.countryId,
        competitionId:this.props.match.params.competitionId,
        eventId:this.props.match.params.eventId
      }))
    this.props.dispatch(getCountryList(this.props.match.params,this.props.languageId,this.props))
    if(this.props.eventInfo && Object.keys(this.props.eventInfo).length>0){
      //this.props.dispatch(getWinBest(this.props.eventInfo.eventId,this.props.languageId));
    }
  }
  componentDidMount(){
    this.props.dispatch(getBanner(this.props.match.params.eventId,this.props.languageId));
    
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.eventInfo.eventId !== nextProps.eventInfo.eventId) {
        //this.props.dispatch(getWinBest(nextProps.eventInfo.eventId,this.props.languageId));
    }
  }
    render() {
        const bannerData = this.props.bannerData;
        return (
          <div className="mainLanding-page">
                    {Object.keys(bannerData).length>0?<MatchBanner data={bannerData}/>:""}
                    <div className="subHeader">
                        {/*<a className="active" href="#home">HEAD TO HEAD</a>
                        <a href="#">SEASONAL STATS</a>*/}
                        <ul>
                          {this.props.isAdvancedMode?<><li className={this.props.dispatch(highlightTab('htoh'))}>
                            <a href="#" onClick={()=>this.props.dispatch(navigateTo(this.props.eventInfo,'h2h',this.props))}>
                              {this.props.staticKeyWords.headToHead}
                            </a>
                          </li>
                          <li className={this.props.dispatch(highlightTab('seasonalstats'))}>
                            <a href="#" onClick={()=>this.props.dispatch(navigateTo(this.props.eventInfo,'seasonalstats',this.props))}>
                            {this.props.staticKeyWords.seasonalStats}
                            </a>
                          </li>
                          <li className={this.props.dispatch(highlightTab('playeranalysis'))}>
                            <a href="#" onClick={()=>this.props.dispatch(navigateTo(this.props.eventInfo,'playeranalysis',this.props,this.props.bannerData.homeTeamId))}>
                            {this.props.staticKeyWords.playerAnalysis}
                            </a>
                          </li></>:""}
                          <li>
                            <a href="#">{this.props.staticKeyWords.preview}</a>
                          </li>
                          {!this.props.isAdvancedMode?<><li className={this.props.dispatch(highlightTab('summary'))}>
                            <a href="#" onClick={()=>this.props.dispatch(navigateTo(this.props.eventInfo,'summary',this.props))}>{this.props.staticKeyWords.summary}</a>
                          </li>
                          <li className={this.props.dispatch(highlightTab('bmrecentforms'))}><a href="#" onClick={()=>this.props.dispatch(navigateTo(this.props.eventInfo,'bmrecentforms',this.props))}>{this.props.staticKeyWords.recentForms}</a></li>
                          <li className={this.props.dispatch(highlightTab('bmhead2head'))}><a href="#" onClick={()=>this.props.dispatch(navigateTo(this.props.eventInfo,'bmhead2head',this.props))}>{this.props.staticKeyWords.headToHead}</a></li>
                          <li className={this.props.dispatch(highlightTab('bmstatistics'))}><a href="#" onClick={()=>this.props.dispatch(navigateTo(this.props.eventInfo,'bmstatistics',this.props))}>{this.props.staticKeyWords.statistics}</a></li></>:""}
                          <li>
                            <a href="#">{this.props.staticKeyWords.odds}</a>
                          </li>
                        </ul>
                        <ul className="changeMode">
                            <li onClick={()=>this.props.dispatch(getToggleMode(this.props.eventInfo,this.props.isAdvancedMode,this.props))}><a href="">{!this.props.isAdvancedMode?this.props.staticKeyWords.advancedMode:this.props.staticKeyWords.basicMode}</a></li>
                        </ul> 
                    </div>
                      <Switch>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/htoh" component={MatchCenter}/>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/htohoutput/htoh" component={MatchCenter}/>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/seasonalstats" render={(props)=><SeasonalStats {...props}/>}/>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/playeranalysis" render={(props)=><PlayerAnalysis {...props}/>}/>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/summary" render={(props)=><Summary {...props}/>}/>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/bmrecentforms" render={(props)=><BMRecentForms {...props}/>}/>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/bmstatistics" render={(props)=><BMStatistics {...props}/>}/>
                        <Route path="/:lang/matchcenter/:countryId/:competitionId/:eventId/bmhead2head" render={(props)=><BMHead2Head {...props}/>}/>
                      </Switch>
          </div>
          
        );
    }
}
function mapStateToProps(state) {
  return {
      bannerData: state.match.bannerData,
      eventInfo: state.app.eventInfo,
      isAdvancedMode:state.match.isAdvancedMode,
      staticKeyWords:state.app.staticKeyWords,
      languageId:state.app.languageId,
      allSeasons:state.match.allSeasons
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({setBanner,getBanner, navigateTo, getToggleMode
    }, dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(Match);