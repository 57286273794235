import React,{Component} from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { formatDate } from '../../sharedfiles/helper'

class SummaryLastResults extends Component{

  componentWillReceiveProps(nextProps) {
    
  }
    componentDidMount(){
        
        
    }
    render(){
        return (
            <table className="tableDefault">
                <thead>
                    <tr>
                        <th>{this.props.staticKeyWords.event}</th>
                        <th>{this.props.staticKeyWords.date}</th>
                        <th>{this.props.staticKeyWords.home}</th>
                        <th>{this.props.staticKeyWords.away}</th>

                        <th>{this.props.staticKeyWords.ftScore}</th>
                        <th>{this.props.staticKeyWords.htScore}</th>
                        <th>{this.props.staticKeyWords.ftResult}</th>
                        <th>{this.props.staticKeyWords.fHAD}</th>
                        <th>{this.props.staticKeyWords.hdc}</th>
                        <th>{this.props.staticKeyWords.hHAD}</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.data.map((item)=>{
                            return <tr>
                                        <td>
                                            <div>{item.competitionName}</div>
                                        </td>
                                        <td>
                                            <div>{(formatDate(item.kickOff,"banner").date)}</div>
                                        </td>
                                        <td>
                                            <div>{item.homeTeamName}</div>
                                        </td>
                                        <td>
                                            <div>{item.awayTeamName}</div>
                                        </td>
                                        <td className="noBorder-td">
                                            <div className="whiteColumn-bg">{item.fullTimeScore}</div>
                                        </td>
                                        <td>
                                            <div>{item.halfTimeScore.length>0?item.halfTimeScore:'-'}</div>
                                        </td>
                
                                        <td>
                                            <div>{item.fullTimeResult}</div>
                                        </td>
                                        <td>
                                            <div><span className={"status"+(item.fullTimeHad.toLowerCase()==="h"?" win":item.fullTimeHad.toLowerCase()==="a"?" loss":" draw")}>{item.fullTimeHad}</span></div>
                                        </td>
                                        <td>
                                            <div className="textIcon"><span>{item.hdcName}</span><span
                                                    className={"status "+(item.hdcOutcome.toLowerCase()==="h"?"win":item.hdcOutcome.toLowerCase()==="a"?"loss":"draw")}>{item.hdcOutcome}</span></div>
                                        </td>
                                        <td>
                                            <div className="textIcon"><span>{item.hHadName}</span><span
                                                    className={"status "+(item.hHadOutcome.toLowerCase()==="h"?"win":item.hHadOutcome.toLowerCase()==="a"?"loss":"draw")}>{item.hHadOutcome}</span></div>
                                        </td>
                                    </tr>
                        })
                    }


                </tbody>
            </table>
        );
    }
    
        
}


function mapStateToProps(state) {
  return {
      data: state.summary.h2hLastResultsTableData,
      staticKeyWords: state.app.staticKeyWords
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({
    }, dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(SummaryLastResults);
