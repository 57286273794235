import React from 'react';

import hilo from '../../assets/images/hilo.png'
import BestBetGraph from '../bestbetgraph/BestBetGraph'
import award from '../../assets/images/award.png';
import {engLangId} from '../../sharedfiles/constants';

export function BestBet(props) {
        return (
            <>
            <div class="bestBet">
                <div class="winPredictor-iconGroup">
                    <div class="winPredictor-icon">
                        <img src={award} alt="award" />
                    </div>
                    <div className={props.languageId===engLangId?"win":"best-chinese"}>{props.best}</div>
                    {props.languageId===engLangId?<div className="predictor">{props.bet}</div>:""}
                </div>
                <div class="bestBet-graph" style={{width:"250px",height:"250px"}}>
                    {Object.keys(props.data).length>0?<BestBetGraph data={props.data}/>:""}
                </div>
                <div class="bestBet-content">
                    <div class="rtb"> {props.data.rtbText} </div>
                </div>
            </div></>
                    
        );
}
