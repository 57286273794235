import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCaller } from '../../api/apiCaller';
import { tournament,seasonalStats } from '../../sharedfiles/EndpointConfig';
import { languageId, channelId} from '../../sharedfiles/constants'
import { getTimeZoneOffset } from '../../sharedfiles/helper';
import { setComepetitionAndDate } from '../tournamentleague/TournamentLeagueSlice';
import { setLoader } from '../../AppSlice';

export const FixturesResultsSlice = createSlice({
  name: 'fixturesresults',
  initialState: {
    data:[],
    carouselData:[],
    selectedCarouselItem:{},
    fixtureFilters:{},
    selectedSeason:'1',
    selectedLeague:'1',
    selectedHHAD:'1',
    selectedHDC:'1',
    selectedCorner:'1',
    selectedOption:'1',
    seasons:[],
    selectedFRSeason:{},
    loaderFR:false
  },
  reducers: {
    setData: (state,action)=>{
      state.data = action.payload;
    },
    setCarouselData:(state,action)=>{
      state.carouselData = action.payload;
      //state.selectedCarouselItem = action.payload[action.payload.length-1];
    },
    selectCarouselItem:(state,action)=>{
      state.selectedCarouselItem = action.payload;
    },
    setFixtureResultsFilters:(state,action)=>{
      state.fixtureFilters = action.payload;
    },
    setFilterOptions:(state,action)=>{
      switch(action.payload.key) {
        case 'Season':
          state.selectedSeason = action.payload.value;
          break;
        case 'Team_League':
          state.selectedLeague = action.payload.value;
          break;
        case 'Team_Corner':
          state.selectedCorner = action.payload.value
          break;
        case 'Team_HDC':
           state.selectedHDC = action.payload.value
          break;
        case 'Team_HHAD':
          state.selectedHHAD = action.payload.value;
          break;
        case 'Options':
          state.selectedOption = action.payload.value
        default:
      }
    },
    generateCarouselData:(state,action)=>{
      let selectedSeason = action.payload; 
      let startDate = new Date(selectedSeason.SeasonFromDate);
      let endDate = new Date(selectedSeason.SeasonToDate);
      let doOperation = true;
      let currentDate = new Date();
      let carouselData=[];
      let selectedCarouselItem={}
      while(doOperation){
        startDate.setMonth(startDate.getMonth() + (carouselData.length===0?0:1));
        console.log("carouselData==dt2==", startDate)
        carouselData.push({
            id: startDate.getFullYear() +""+ (startDate.getMonth() + 1),
            fullDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
            date: startDate.getDate(),
            month: startDate.getMonth() + 1,
            year: startDate.getFullYear()
        });
        if(currentDate.getMonth() === startDate.getMonth() && currentDate.getFullYear() === startDate.getFullYear()){
          selectedCarouselItem = {
            id: startDate.getFullYear() +""+ (startDate.getMonth() + 1),
            fullDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
            date: startDate.getDate(),
            month: startDate.getMonth() + 1,
            year: startDate.getFullYear()
          }
        }
        if(startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()){
          doOperation =false;
        }
      }
      if(Object.keys(selectedCarouselItem).length===0){
        state.selectedCarouselItem = carouselData[0]
      }else{
        state.selectedCarouselItem = selectedCarouselItem;
      }
      console.log("carouselData===",carouselData);
      state.carouselData = carouselData;
    },
    setLeagueSeason:(state,action)=>{
      state.selectedFRSeason = action.payload; 
    },
    setSeasonsData:(state,action)=>{
      state.seasons = action.payload;
      state.selectedFRSeason = action.payload[0]; 
      
    }
  },
});

export const { setData, setCarouselData, selectCarouselItem, setFixtureResultsFilters, setFilterOptions, setSeasonsData, generateCarouselData,setLeagueSeason } = FixturesResultsSlice.actions;

export const getFixturesResultsData = (params,languageId) => dispatch => {
  dispatch(setData({}))
  dispatch(setLoader(true));
  var url="";
  if(params.isLeague){
    url = tournament.getFixturesResults+'?languageId='+languageId+'&channelId='+channelId+'&competitionId='+params.compId+'&date='+params.date+'&tzOffSet='+getTimeZoneOffset();
  }else{
    url = tournament.getTeamFixturesResults+'?languageId='+languageId+'&channelId='+channelId+'&teamId='+params.teamId+"&SeasonId="+params.selectedSeason+"&OptionId="+params.selectedOption+"&HHAD="+params.selectedHHAD+"&HDC="+params.selectedHDC+"&LeagueId="+params.selectedLeague+"&Corner="+params.selectedCorner;
    // url = tournament.getTeamFixturesResults+'?languageId='+languageId+'&channelId='+channelId+'&teamId='+params.teamId+'&date='+params.date+'&tzOffSet='+getTimeZoneOffset();
  }
  
  apiCaller(url, 'GET', {}).then(response => {
    if(response && response.data!=undefined && response.data ){
      dispatch(setData(response.data))
      dispatch(setLoader(false));
      dispatch(setComepetitionAndDate({
        competitionName:response.data.hasOwnProperty('competitionName')?response.data.competitionName:response.data.teamName,
        lastUpdated:response.data.lastUpdated
      }))
    }else{
      dispatch(setData({}))
      dispatch(setLoader(false));
      // dispatch(setLoader(false));
      //dispatch(setBanner(response.data));
    }
  });

}

export const getFixtureResultsFilters = (languageId) => dispatch => {
    var url = tournament.fixtureResultsFilters+"?channelId="+channelId+"&languageId="+languageId;
    apiCaller(url,'GET',{}).then(response =>{
      if(response && response.data!=undefined && response.data){
        dispatch(setFixtureResultsFilters(response.data))
        for(var property in response.data){
          dispatch(setFilterOptions({"key":property,"value":response.data[property][0].id}))
        }
      }
    })

}

export const getFixturesResultsSeasons = (params,languageId) => dispatch=>{
  var url = tournament.getSeasons+"?channelId="+channelId+"&languageId="+languageId+"&competitionId="+params.compId;
    apiCaller(url,'GET',{}).then(response =>{
      if(response && response.data!=undefined && response.data.length>0){
        dispatch(setSeasonsData(response.data))
        dispatch(generateCarouselData(response.data[0]))
      }
    })

}




export default FixturesResultsSlice.reducer;
