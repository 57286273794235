import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCaller } from '../../api/apiCaller';
import { tournament,seasonalStats } from '../../sharedfiles/EndpointConfig';
import { languageId, channelId} from '../../sharedfiles/constants'
import { setLoader } from '../../AppSlice';
import { setComepetitionAndDate } from '../tournamentleague/TournamentLeagueSlice';

export const TournamentStandingsSlice = createSlice({
  name: 'tournamentstandings',
  initialState: {
    optionsList:[],
    seasonFilterData:[],
    selectedSeasonFilter:1,
    selectedTab:1,
    standingColumns:[],
    standingsData:{},
    staticKeyWords:{}
  },
  reducers: {
    setTab:(state,action)=>{
      state.selectedTab = action.payload;
    },
    setTableHeaders:(state,action)=>{
      var staticKeyWords = action.payload;
      state.staticKeyWords = staticKeyWords;
      state.standingColumns = [
        {column:staticKeyWords.tournamentRank,key:"teamRank",isSplit:false},
        {column:staticKeyWords.team,key:"teamName",isSplit:false},
        {column:staticKeyWords.p,key:"teamPlayed",isSplit:false},
        {column:staticKeyWords.w,key:"teamWin",isSplit:false},
        {column:staticKeyWords.d,key:"teamDraw",isSplit:false},
        {column:staticKeyWords.l,key:"teamLoss",isSplit:false},
        {column:staticKeyWords.gf,key:"teamGoalsFor",isSplit:false},
        {column:staticKeyWords.ga,key:"teamGoalsAway",isSplit:false},
        {column:staticKeyWords.gd,key:"teamGoalsDiff",isSplit:false},
        {column:staticKeyWords.pts,key:"teamPoints",isSplit:false},
        {column:staticKeyWords.form,key:"teamForm",isSplit:true},
      ];
    },
    setFilterData:(state,action)=>{
      if(action.payload.response && 
        action.payload.response.Season && 
        action.payload.response.Season.length>0){
         state.seasonFilterData = action.payload.response.Season;
      }
      if(action.payload.response && 
        action.payload.response.Options && 
        action.payload.response.Options.length>0){
         state.optionsList = action.payload.response.Options;
      }
    },
    setFilter: (state,action)=>{
        state.selectedSeasonFilter = action.payload.value
    },
    setData: (state,action)=>{
      state.standingsData = action.payload
    }
  },
});

export const { setTab, setData, setFilterData, setFilter, setTableHeaders } = TournamentStandingsSlice.actions;

export const getStandingsData = (params,leagueType,languageId) => dispatch => {
  dispatch(setData({}))
  dispatch(setLoader(true));
  var url="";
  var leagueTypeId = leagueType=='league'?1:leagueType=='cup'?2:3;
  if(params.isLeague){
    url = tournament.getStandings+'?languageId='+languageId+'&channelId='+channelId+'&competitionId='+params.compId+'&optionId='+params.optionId+'&seasonId='+params.seasonId+'&categoryId='+leagueTypeId;
  }else{
    url = tournament.getTeamStandings+'?languageId='+languageId+'&channelId='+channelId+'&competitionId='+params.compId+'&teamId='+params.teamId+'&optionId='+params.optionId+'&seasonId='+params.seasonId+'&categoryId='+leagueTypeId;
  }
  
  apiCaller(url, 'GET', {}).then(response => {
    // if(response && response.data && response.data.length>0){
    if(response && response.data){
      dispatch(setData(response.data))
      dispatch(setComepetitionAndDate({
        competitionName:response.data.hasOwnProperty('competitionName')?response.data.competitionName:response.data.teamName,
        lastUpdated:response.data.lastUpdated
      }))
    }
      //dispatch(setBanner(response.data));
    dispatch(setLoader(false));
  });

}
export const getFiltersData = (tableId,languageId) => dispatch =>{
  var url = seasonalStats.getFilters+"?languageId="+languageId+"&channelId="+channelId+"&tableId="+tableId;
  apiCaller(url,'GET',{}).then(response => {
    if(response.data && Object.keys(response.data)){
      dispatch(setFilterData({tableId:tableId,response:response.data}))
    } 
  })
}


export default TournamentStandingsSlice.reducer;
