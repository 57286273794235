import React,{Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { getTopScorerData, setTableHeaders } from './TopScorerSlice';
import StandingsTable from '../standingstable/StandingsTable';
import TeamsGroupTable from '../teamsgrouptable/teamsGroupTable';
import { BrowserRouter as Router, Route} from 'react-router-dom';

class TopScorer extends Component{
    componentWillMount(){
        if(this.props.match && this.props.match.params.leagueId && Number(this.props.match.params.leagueId)>0){
          this.props.dispatch(getTopScorerData(this.props.match.params.leagueId,this.props.languageId))
        }
        if(this.props.staticKeyWords && Object.keys(this.props.staticKeyWords)){
          this.props.dispatch(setTableHeaders(this.props.staticKeyWords))
        }
    }
    componentWillReceiveProps(nextProps) {
        if(JSON.stringify(this.props.staticKeyWords) !== JSON.stringify(nextProps.staticKeyWords)){
          this.props.dispatch(setTableHeaders(nextProps.staticKeyWords))
        }
    }
    componentDidMount(){
        
        
    }
    render(){
        return (
          <React.Fragment>
            <Route render={(props)=> <TeamsGroupTable 
                    standingsTableData={this.props.topScorerData.hasOwnProperty('info')?this.props.topScorerData.info:[]}
                    screenfrom='topscorer'
                    />} />
            {this.props.topScorerData.hasOwnProperty('info') && this.props.topScorerData.info.length>=11 && <div id="leagueTopScorer" className="tabContent">
              <div className="tournament-content p-0">
               <StandingsTable standingColumns={this.props.topScorerColumns} 
                                  standingsTableData={this.props.topScorerData.hasOwnProperty('info') && this.props.topScorerData.info}
                                  isWhiteBG={true} isTournament={true}/>
              </div>
            </div>}  
          </React.Fragment>  
        );
        {/*<div id="leagueTopScorer" className="tabContent">
            <div className="tournament-content">
             <StandingsTable standingColumns={this.props.topScorerColumns} 
                                standingsTableData={this.props.topScorerData.hasOwnProperty('info') && this.props.topScorerData.info}
                                isWhiteBG={true}/>
            </div>
        </div> */}
    }
    
        
}


function mapStateToProps(state) {
  return {
    topScorerData: state.topscorer.topScorerData,
    topScorerColumns: state.topscorer.topScorerColumns,
    staticKeyWords: state.app.staticKeyWords,
    languageId:state.app.languageId
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({
    }, dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(TopScorer);
