import React,{Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import Loading from '../../loading/Loading';

class KeyIndicators extends Component{
    componentDidMount(){
        
    }
    componentDidUpdate(prevProps, prevState) {
        
    }
    render(){
        
        return <table class="tableDefault tableSeasonal-stats">
            <thead>
                <tr>
                {
                    this.props.standingColumns ? this.props.standingColumns.map((item,index)=>{
                        if(item.hasOwnProperty('isRow')){
                        return <React.Fragment key={index}>
                            {(item.isRow)?
                                <th className={item.className} rowSpan={item.spanValue}>{item.column}</th>:
                                <th className={item.className} colSpan={item.spanValue}>{item.column}</th>
                            }
                        </React.Fragment>  
                        }         
                    }):<React.Fragment></React.Fragment>
                }
                
                </tr>
                <tr>
                {
                    this.props.standingColumns ? this.props.standingColumns.map((item,index)=>{
                        return <React.Fragment key={index}>
                            {(!item.hasOwnProperty('isRow')) &&
                                <th className={item.className}>{item.column}</th>
                            }
                        </React.Fragment>           
                    }):<React.Fragment></React.Fragment>
                }
                </tr>
            </thead>
            <tbody>
                {this.props.standingsTableData.length?
                    this.props.standingsTableData.map((rowData,rowIndex)=>{
                        return <tr key={rowIndex} className={ this.props.isTournament?
                        (this.props.type=='team'?(this.props.teamId==rowData.teamId?'neutral':''):''):
                        (this.props.homeTeamId==rowData.teamId?"homeBg":this.props.awayTeamId==rowData.teamId?"awayBg":"")}>
                            {
                                this.props.standingColumns && this.props.standingColumns.map((columndata,columnIndex)=>{
                                    if(columndata.isDisplay){
                                        var obj = columndata["key"].split('.');
                                        return  <td key={columnIndex}>
                                                    <div>{(obj.length>1)?rowData[obj[1]]:rowData[columndata['key']]}</div>
                                                    {/*<div>{(obj.length>1)?rowData[obj[0]][obj[1]]:rowData[columndata['key']]}</div>*/}
                                                </td>
                                    }
                                    
                                })
                            }
                        </tr>
                    }):
                    <tr>
                        <td colspan={this.props.standingColumns.length}>
                            <Loading isLoader={this.props.loader} isNoData={!this.props.loader && !this.props.standingsTableData.length} temporarilyNoData={this.props.staticKeyWords.temporarilyNoData}/>
                        </td>
                    </tr>
                }
            </tbody>
        </table>  
    }
}
function mapStateToProps(state) {
    return {
        homeTeamId: state.match.bannerData.homeTeamId,
        awayTeamId: state.match.bannerData.awayTeamId,
        loader: state.app.loader,
        staticKeyWords: state.app.staticKeyWords
    };
  }
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: bindActionCreators({}, dispatch)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(KeyIndicators);
