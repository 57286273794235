import React,{Component} from 'react';
import Highcharts from '../../sharedfiles/customhighcharts';


class HiLoGraph extends Component{
    componentDidMount(){
        this.loadGraph();
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("loadGraph==",prevProps.data !== this.props.data)
        if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) && Object.keys(this.props.data).length>0){
            this.loadGraph();
        }
    }
    loadGraph(){
        console.log("loadGraph==",this.props.data)
        //roundedCorner(Highcharts);
        let categories=[];
        let hiData=[];
        let loData=[];
        for(var item of this.props.data){
            categories.push(item.line);
            hiData.push(item.high);
            loData.push(item.low)
        }
        var myChart = Highcharts.chart(this.props.loadIn, {
            chart: {
                type: 'bar',
                backgroundColor:"rgba(255, 255, 255, 0.0)"
                              
            },
            title: {
                text: this.props.title,
                style:{
                    color:"#FFF"
                }
            },
            xAxis: {
                gridLineWidth:0,
                className:"xaxis-border",
                categories: categories,
                lineWidth: 0,
                labels:{
                    useHTML:true,
                    style:{
                        color:"#FFF"
                    },
                },
                labelOptions:{
                    useHTML:false,
                   

                },
                title:{
                    text:'Line',
                    rotation:'360',
                    useHTML:true,
                    x:20,
                    y:-70,
                    style:{
                        color:"#FFF",
                        fontWeight:"bold"
                    },
                },
                offset:10,
                plotBands:[{
                    borderWidth:10,
                    
                }],
                plotLines:[{
                    width:10,
                    color:"red"
                }]
            },
            yAxis: {
                title: {
                    text: ''
                },
                gridLineWidth:0,
                min: 0,
                max:10,
                labels:{
                    enabled:false
                }
            },
            
            tooltip: {
                enable:false
            },
            legend: {
                enabled:true,
                align:'right',
                verticalAlign:'top',
                borderColor:"#FFF",
                borderWidth:1,
                itemMarginTop:0,
                y:-50,
                useHTML:true,
                // borderRadius:"50",
                itemHoverStyle:{
                    color:"#FFF"
                },
                itemStyle:{
                    color:'#FFF',
                }
                
            },
            colors: [
            '#28d0ce',
            '#e47273'
            ],

            plotOptions: {
                series: {
                    stacking: 'normal',

                    dataLabels: {
                        enabled: true,
                        color: '#FFFFFF',
                        textAlign:'left' 
                    },
                }
            },
            series: [{
                        name: 'Lo',
                        data: loData,
                        borderRadiusTopLeft: '50%',
                        borderRadiusTopRight: '50%',
                        borderColor:"#28d0ce"
                    }, {
                        name: 'Hi',
                        data: hiData,
                        borderRadiusBottomLeft: '50%',
                        borderRadiusBottomRight: '50%',
                        borderColor:"#e47273"
                    }]
        });
    }
    render(){
        return (
            <div id={this.props.loadIn} style={{"width":"400px", "height":"200px"}}></div>       
        );
    }
    
        
}

export default HiLoGraph;
