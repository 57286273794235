import React,{Component} from 'react';
import Highcharts from '../../sharedfiles/customhighcharts';


class DynamicHADGraph extends Component{
    componentDidMount(){
        if(this.props.had && Object.keys(this.props.had).length>0){
            this.loadHADGraph();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // console.log("loadGraph==",prevProps.data !== this.props.data)
        // if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) && Object.keys(this.props.data).length>0){
        //     this.loadGraph();
        // }
        if(JSON.stringify(prevProps.had) !== JSON.stringify(this.props.had) && Object.keys(this.props.had).length>0){
            this.loadHADGraph();
        }
    }
    loadHADGraph(){
        console.log("loadGraph==HAD==",this.props.had)
        //roundedCorners(Highcharts);
        var myChart = Highcharts.chart(this.props.loadin, {
            chart: {
                type: this.props.type,
                backgroundColor:"rgba(255, 255, 255, 0.0)",
            },
            title: {
                text: this.props.title,
                style:{
                    color:'#FFF'
                }
                
            },
            xAxis: {
                gridLineWidth:0,
                categories: this.props.categories,
                lineWidth: 0,
                labels: {
                style: {
                    fontWeight:'normal',
                    fontSize:'14px',
                    // fontFamily: '"Montserrat", sans-serif;font-weight:normal',
                },
                formatter() {
                    return '<span style="color: ' + (this.chart.options.colors[this.pos]==="#666666"?"#FFFFFF":this.chart.options.colors[this.pos]) + '";"-webkit-text-stroke:px black">' + this.value + '</span>';
                },
                
            }
            },
            yAxis: {
                title: {
                    text: ''
                },
                gridLineWidth:0,
                min: 0,
                max:10,
                labels:{
                    enabled:false
                },
            },
            tooltip: {
                enable:false
            },
            legend: {
                enabled:false
            },
            colors: this.props.colors,
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        align: 'center',
                        inside:true,
                        style:{
                            textOutline:'0px',
                            fontWeight:'normal',
                            fontSize:'12px',
                            // fontFamily: '"Montserrat", sans-serif;font-weight:normal',
                        },
                        textAlign:'left'
                       
                    },
                    enableMouseTracking: false,
                    borderWidth:0,
                    borderRadiusTopLeft: '50%',
                    borderRadiusTopRight: '50%'
                }
            },
            series: [{
                name: 'Population',
                colorByPoint: true,
                data: [
                    [this.props.had.first],
                    [this.props.had.middle],
                    [this.props.had.last],
                   
                ]
            }]
        });
    }
    render(){
        return (
            <>
            <div id={this.props.loadin} style={{"width":this.props.width?this.props.width:"400px", "height":this.props.height?this.props.height:"200px"}}></div>       
            </>
        );
    }
    
        
}

export default DynamicHADGraph