export var engLangId=19;
export var cheLangId=18;
export var languageId=1;
export const channelId=1;
export const GoalsGraph={
    min:0,
    max:20
}

export const defaultH2HFilterId = 2;
export const staticEng ={
    match:"Match",
    tournament:"Tournament",
    headTOHeadSearch:"Head To Head Search",
    teamSearch:"Team Search",
    SearchByTeamName:"Search by team name",
    homeForm:"Home Form",
    awayForm:"Away Form",
    hadPredictor:"Win Predictor",
    bestBet:"Best Bet",
    best:"Best",
    bet:"Bet",
    date:"Date",
    mcDate:"Date",
    faq:"FAQ",
    contactUs:"Contact Us",
    responsibleGamblingPolicy:"Responsible Gambling Policy",
    privacy:"Privacy",
    disclaimerUs:"Disclaimer Us",
    securityTips:"Security Tips",
    copyRightText:"Copyright @ 2000-2020 The Hong Kong Jockey Club. All Rights Reserved.",
    rules:"Rules",
    footerText:"The entire service of FootyLogic is provided by Bettorlogic and it is intended to be used for reference only. Bettorlogic shall not be liable to any person for any loss or damage suffered by such person as a result of the use of the FootyLogic. Please browse the page using IE9.0 and above, Chrome, Firefox and Safari for the optimum viewing. Rankings referenced in these pages are according to FIFA World Rankings (for international teams), and Bettorlogic’s club rankings (for club cup tournaments).",
    headToHead:"Head To Head",
    headToHead8RE:"Head To Head (8 Recent Encounters)",
    mcH2H:"Head To Head",
    seasonalStats:"Seasonal Stats",
    playerAnalysis:"Player Analysis",
    preview:"Preview",
    odds:"Odds",
    basicMode:"Basic Mode",
    advancedMode:"Advanced Mode",
    summary:"Summary",
    recentForms:"Recent Form",
    statistics:"Statistics",
    hadHandicap:"HAD & Handicap",
    hiLoCorners:"HiLo & Corners",
    hiLoHandicap:"HiLo & Handicap",
    predictor:"Predictor",
    last8Encounters:"Last 8 Encounters",
    season:"Season",
    comp:"Comp",
    competition:"Competition",
    homeTeam:"Home Team",
    pos:"Pos",
    score:"Score",
    ht:"HT",
    scoreHt:"Score (HT)",
    awayTeam:"Away Team",
    had:"HAD",
    fHAD:"FHAD",
    hdc:"HDC",
    hHAD:"HHAD",
    more:"More",
    home:"Home",
    away:"Away",
    draw:"Draw",
    won:"Won",
    lost:"Lost",
    homeAwayDraw:"Home/Away/Draw",
    firstHalfHAD:"First Half HAD",
    goalsPerGame:"Goals Per Game",
    per:"Per",
    game:"Game",
    handicapHAD:"Handicap HAD",
    fullTime:"Full Time",
    halfTime:"Half Time",
    haFu:"HaFu",
    handicap:"Handicap",
    handicaps:"Handicaps",
    correctScore:"Correct Score",
    csHafu:"CORRECT SCORE & HaFu",
    ttg:"TTG",
    fhHiLo:"FH HiLo",
    hiLo:"HiLo",
    btts:"Both Teams to Score",
    bts:"Both to Score",
    tts:"Teams To Score",
    cornersHiLo:"Corners HiLo",
    goal:"Goal",
    line: "Line",
    ftHiLo: "FT HiLo",
    htHiLo:"HT HiLo",
    cornersPerGame:"Corners Per Game",
    cornersForPerGame: "Corners For Per Game",
    cornersAgainstPerGame: "Corners Against Per Game",
    cornersFor: "Corners For",
    for:"for",
    cornersAgainst: "Corners Against",
    totalGoals:"Total Goals",
    totalGoalsAnalysis:"Total Goals Analysis",
    total:"Total",
    goalTimes:"Goal Times",
    firstToScore:"First To Score",
    corners:"Corners",
    recentResults:"8 Recent Results",
    event:"Event",
    ha:"H/A",
    against:"Against",
    ftScore:"FT Score",
    htScore:"HT Score",
    ftResult:"FT Result",
    result:"Result",
    win:"Win",
    loss:"Loss",
    all:"All",
    team:"Team",
    p:"P",
    w:"W",
    d:"D",
    l:"L",
    h:"H",
    gf:"GF",
    ga:"GA",
    gd:"GD",
    pts:"Pts",
    form:"Form",
    highIcon:"H",
    lowIcon:"L",
    lossIcon:"L",
    hdcWinIcon:"W",
    hdcLossIcon:"L",
    hadWinIcon:"W",
    hadLossIcon:"L",
    a:"A",
    rank:"Rank",
    tournamentRank:"Rank",
    draws:"Draws",
    wins:"Wins",
    losses:"Losses",
    fullWin:"Full Win",
    halfWin:"Half Win",
    halfLoss:"Half Loss",
    fullLoss:"Full Loss",
    fullWins:"Full Wins",
    halfWins:"Half Wins",
    halfLosses:"Half Losses",
    fullLosses:"Full Losses",
    ww:"WW",
    dw:"DW",
    lw:"LW",
    wd:"WD",
    dd:"DD",
    ld:"LD",
    wl:"WL",
    wbyl:"W/L",
    fhwbyl:"FH W/L",
    dl:"DL",
    ll:"LL",
    avarage:"Avarage",
    plus:"+",
    minus:"-",
    hi:"Hi",
    lo:"Lo",
    teamScore:"Team Score",
    odd:"Odd",
    even:"Even",
    scored1st:"Scored 1st",
    conceded1st:"Conceded 1st",
    scoredFirst:"Scored First",
    concededFirst:"Conceded First",
    scoredLast:"Scored Last",
    concededLast:"Conceded Last",
    seasonRankingTrend:"Season Ranking Trend",
    hdcTypeComparison:"HDC Type Comparison",
    handicapHADComparison:"Handicap HAD Comparison",
    hadComparison:"HAD Comparison",
    hafuComparison:"HaFu Comparison",
    ttsOEComparison:"Odd/Even Comparison",
    ffsComparison:"First to Score Comparison",
    correctScoreComparision:"Correct Score Comparison",
    cornersComparision:"Corners Comparision",
    totalGoalsComparison:"Total Goals Comparison",
    temporarilyNoData:"Temporarily no information available",
    player:"Player",
    teamPlayer:"Player",
    started:"Started",
    missed:"Missed",
    goalsFor:"Goals For",
    with:"With",
    wo:"W/O",
    winPer:"Win%",
    drawPer:"Draw%",
    losePer:"Lose%",
    goalsAgainst:"Goals Against",
    league:"League",
    cup:"Cup",
    international:"International",
    standings:"Standings",
    fixturesResults:"Fixtures/Results",
    topScorer :"Top Scorer",
    players:"Players",
    club:"Club",
    nationality:"Nationality",
    goals:"Goals",
    hKJCNo:"TPS No.",
    squad:"Squad",
    position:"Position",
    age:"Age",
    appearances:"Appearances",
    assists:"Assists",
    generate:"Generate",
    type:"Type",
    country:"Country",
    continent:"Continent",
    h2hTitle:"Enter Team Names",
    last8HomeResults:"Last 8 Home Results",
    last8HomeResultsLFL:"Last 8 Home Results (Latest from left)",
    last8AwayResultsLFL:"Last 8 Away Results (Latest from left)",
    latestFromLeft:"Latest from left",
    played:"Played",
    recentEncounters:"Recent Encounters",
    firstHalfHiLo:"FIRST HALF HiLo",
    last8HomeResults:"Last 8 Home Results",
    last8AwayResults:"Last 8 Away Results",
    seasonSummary:"Season Summary",
    high:"High",
    low:"Low",
    goalScoringTimeAnalysis:"GOALSCORING TIME ANALYSIS",
    Recent8Homeleaguematches:"Recent 8 Home league matches",
    Recent8AwayLeagueMatches:"Recent 8 Away league matches",
    nextMatch:"Next Match",
    matchResult:"Match Result",
    past3Seasons:"Past 3 Seasons",
    point:"Points",
    wprc:"W%",
    both:"Both",
    ft:"FT",
    average:"Average",
    matches:"Matches",
    scoreFirst:"Score First",
    oppositScoreFirst:"Opposite Score First",
    leagueTable:"League Table",
    firstHalfCorrectScore:"FIRST HALF CORRECT SCORE",
    firstTeamToScore:"First Team to Score",
    cornerHiLo:"Corner HiLo",
    search:"Search",
    orselectbelow:"SELECT 2 TEAMS",
    yellowcards:"Yellow Cards",
    redcards:"Red Cards",
    subbedon:"Subbed On",
    subbedoff:"Subbed Off",
    firstHalf:"First Half",
    gpg:"GPG",
    enterteamname:"Enter team name",
    jan:"Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul:"Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    january:'January',
    february:'February',
    march:'March',
    april:'April',
    may:'May',
    june:'June',
    july:'July',
    august:'August',
    september:'September',
    october:'October',
    november:'November',
    december:'December',
    sunday:'Sunday',
    monday:'Monday',
    tuesday:'Tuesday',
    wednesday:'Wednesday',
    thursday:'Thursday',
    friday:'Friday',
    saturday:'Saturday',
    nogoals:"No Goals",
    noupcominggames:'No upcoming games',
    koTime:"KO Time"
}
export const staticChe ={
    match:"賽事",
    tournament:"聯賽/盃賽",
    headTOHeadSearch:"對賽搜尋",
    teamSearch:"Team Search",
    SearchByTeamName:"球隊名稱",
    homeForm:"主隊近況",
    awayForm:"客隊近況",
    hadPredictor:"勝負預測",
    bestBet:"投注推介",
    date:"投注推介",
    mcDate:"日期",
    faq:"FAQ",
    contactUs:"Contact Us",
    responsibleGamblingPolicy:"Responsible Gambling Policy",
    privacy:"Privacy",
    disclaimerUs:"Disclaimer Us",
    securityTips:"Security Tips",
    copyRightText:"Copyright @ 2000-2020 The Hong Kong Jockey Club. All Rights Reserved.",
    rules:"賽制",
    footerText:"The entire service of FootyLogic is provided by Bettorlogic and it is intended to be used for reference only. Bettorlogic shall not be liable to any person for any loss or damage suffered by such person as a result of the use of the FootyLogic. Please browse the page using IE9.0 and above, Chrome, Firefox and Safari for the optimum viewing. Rankings referenced in these pages are according to FIFA World Rankings (for international teams), and Bettorlogic’s club rankings (for club cup tournaments).",
    headToHead:"對賽往績",
    headToHead8RE:"往績 (近8次對賽結果)",
    seasonalStats:"季績統計",
    playerAnalysis:"球員啟示",
    preview:"賽前分析",
    odds:"賠率",
    mcH2H:"往績",
    basicMode:"Basic Mode",
    advancedMode:"進入進階版數據中心",
    summary:"拆局",
    recentForms:"近況",
    statistics:"季績統計",
    hadHandicap:"主客和及讓球",
    hiLoCorners:"入球大細及角球",
    hiLoHandicap:"HiLo & Handicap",
    mcWinPredictor:"勝負預測",
    last8Encounters:"近8次對賽結果",
    season:"Season",
    comp:"Comp",
    competition:"賽事",
    homeTeam:"主隊",
    pos:"排名",
    score:"Score",
    ht:"HT",
    scoreHt:"賽果(半場)",
    awayTeam:"客隊",
    had:"主客和",
    fHAD:"半場主客和",
    hdc:"讓球",
    hHAD:"讓球主客和",
    more:"More",
    home:"主場",
    away:"客場",
    draw:"和",
    won:"Won",
    lost:"Lost",
    homeAwayDraw:"主客和",
    firstHalfHAD:"First Half HAD",
    goalsPerGame:"場均入球",
    per:"Per",
    game:"Game",
    handicapHAD:"讓球主客和",
    fullTime:"全場",
    halfTime:"半場",
    haFu:"半全場",
    handicap:"讓球",
    handicaps:"讓球",
    correctScore:"波膽",
    csHafu:"波膽/半全場",
    ttg:"總入球",
    fhHiLo:"半場入球大細",
    hiLo:"入球大細",
    btts:"Both Teams to Score",
    bts:"Both to Score",
    tts:"Teams To Score",
    cornersHiLo:"角球大細",
    goal:"Goal",
    line: "球數",
    ftHiLo: "FT HiLo",
    htHiLo:"HT HiLo",
    cornersPerGame:"場均所得角球",
    cornersForPerGame: "場均所得角球",
    cornersAgainstPerGame: "場均所失角球",
    cornersFor: "Corners For",
    for:"for",
    cornersAgainst: "Corners Against",
    totalGoals:"總入球",
    totalGoalsAnalysis:"Total Goals Analysis",
    total:"Total",
    goalTimes:"入球時間",
    firstToScore:"First To Score",
    corners:"角球總數",
    recentResults:"近8場賽績",
    event:"Event",
    ha:"主/客",
    against:"對手",
    ftScore:"FT Score",
    htScore:"HT Score",
    ftResult:"勝負",
    result:"Result",
    win:"勝",
    loss:"負",
    all:"全部賽事",
    team:"球隊",
    p:"賽事",
    w:"勝",
    d:"和",
    l:"負",
    highIcon:"大",
    lowIcon:"細",
    h:"主",
    lossIcon:"輸",
    hadWinIcon:"勝",
    hadLossIcon:"負",
    hdcWinIcon:"贏",
    hdcLossIcon:"輸",
    a:"客",
    gf:"得球",
    ga:"失球",
    gd:"球差",
    pts:"積分",
    form:"Form",
    rank:"排名",
    tournamentRank:"名次",
    draws:"和",
    wins:"Wins",
    losses:"Losses",
    fullWin:"全贏",
    halfWin:"贏半",
    halfLoss:"輸半",
    fullLoss:"全輸",
    fullWins:"Full Wins",
    halfWins:"Half Wins",
    halfLosses:"Half Losses",
    fullLosses:"Full Losses",
    ww:"贏贏",
    dw:"和贏",
    lw:"輸贏",
    wd:"贏和",
    dd:"和和",
    ld:"輸和",
    wl:"贏輸",
    wbyl:"勝負",
    fhwbyl:"FH W/L",
    dl:"和輸",
    ll:"輸輸",
    avarage:"平均總入球",
    plus:'受讓"+"',
    minus:'讓球"-"',
    hi:"Hi",
    lo:"Lo",
    teamScore:"Team Score",
    odd:"單",
    even:"雙",
    scored1st:"先入球",
    conceded1st:"先失球",
    scoredFirst:"Scored First",
    concededFirst:"Conceded First",
    scoredLast:"Scored Last",
    concededLast:"Conceded Last",
    seasonRankingTrend:"Season Ranking Trend",
    hdcTypeComparison:"讓球比較",
    handicapHADComparison:"讓球主客和比較",
    hadComparison:"主客和比較",
    hafuComparison:"半全場比較",
    ttsOEComparison:"入球單雙比較",
    ffsComparison:"第一隊入球比較",
    correctScoreComparision:"波膽比較",
    totalGoalsComparison:"總入球比較",
    temporarilyNoData:"暫時未有資料提供",
    player:"球員",
    teamPlayer:"姓名",
    started:"正選",
    missed:"缺陣",
    goalsFor:"球隊入球",
    with:"正選",
    wo:"缺陣",
    winPer:"勝 (%)",
    drawPer:"和 (%)",
    losePer:"負 (%)",
    goalsAgainst:"球隊失球",
    league:"聯賽",
    cup:"盃賽",
    international:"國家隊",
    standings:"Standings",
    fixturesResults:"賽程/賽果",
    topScorer :"神射手",
    players:"Players",
    club:"球會",
    nationality:"Nationality",
    goals:"入球",
    hKJCNo:"神射手編號",
    squad:"編號",
    position:"位置",
    age:"年齡",
    appearances:"上陣次數",
    assists:"Assists",
    generate:"Generate",
    type:"賽事",
    country:"國家",
    continent:"Continent",
    h2hTitle:"輸入球隊名稱s",
    last8HomeResults:"Last 8 Home Results",
    last8HomeResultsLFL:"近8場主場成績 (左為最新)",
    last8AwayResultsLFL:"近8場作客成績 (左為最新)",
    latestFromLeft:"Latest from left",
    played:"Played",
    recentEncounters:"Recent Encounters",
    firstHalfHiLo:"FIRST HALF HiLo",
    last8HomeResults:"Last 8 Home Results",
    last8AwayResults:"Last 8 Away Results",
    seasonSummary:"賽季統計",
    high:"High",
    low:"Low",
    goalScoringTimeAnalysis:"GOALSCORING TIME ANALYSIS",
    Recent8Homeleaguematches:"Recent 8 Home league matches",
    Recent8AwayLeagueMatches:"Recent 8 Away league matches",
    nextMatch:"下仗秩序",
    matchResult:"Match Result",
    past3Seasons:"Past 3 Seasons",
    point:"Point",
    wprc:"W%",
    both:"Both",
    ft:"FT",
    average:"平均總入球",
    matches:"賽事",
    scoreFirst:"Score First",
    oppositScoreFirst:"Opposite Score First",
    leagueTable:"League Table",
    firstHalfCorrectScore:"FIRST HALF CORRECT SCORE",
    firstTeamToScore:"第一隊入球",
    cornerHiLo:"Corner HiLo",
    firstHalf:"First Half",
    gpg:"GPG",
    search:"搜尋",
    orselectbelow:"選擇球隊",
    yellowcards:"Yellow Cards",
    redcards:"Red Cards",
    subbedon:"後備入替",
    subbedoff:"被換出",
    enterteamname:"輸入球隊名稱",
    jan:"一月",
    feb: "二月",
    mar: "三月",
    apr: "四月",
    may: "五月",
    jun: "六月",
    jul:"七月",
    aug: "八月",
    sep: "九月",
    oct: "十月",
    nov: "十一月",
    dec: "十二月",
    january:'一月',
    february:'二月',
    march:'三月',
    april:'四月',
    may:'五月',
    june:'六月',
    july:'七月',
    august:'八月',
    september:'九月',
    october:'十月',
    november:'十一月',
    december:'十二月',
    sunday:'星期日',
    monday:'星期一',
    tuesday:'星期二',
    wednesday:'星期三',
    thursday:'星期四',
    friday:'星期五',
    saturday:'星期六',
    nogoals:"無入球",
    noupcominggames:'No upcoming games',
    koTime:"KO Time"
}
