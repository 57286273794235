import React from 'react';

export function Rules(props) {
        return (
            <div id="leagueRules" className="tabContent">
                <div className="tournament-content league-rules">
                    <div className="league-rules-content">
                        <div className="sectionHeading">
                            <div className="sectionTitle">FORMAT</div>
                        </div>

                        <div className="rules-label">Competition Type:</div> Domestic League
                        <br />
                        <div className="rules-label">Tier:</div> 1
                        <br />
                        <div className="rules-label">Teams:</div> 20
                        <br />

                        <div className="rules-label">Structure:</div> Double round-robin system where teams
                        play every other team home and away
                        once across the season.
                        <br />
                        <div className="rules-label">Promotion/Relegation :</div> The teams that finish in
                        the bottom 3 are relegated.
                        <br />
                        <div className="rules-label">Tie-Break Rules</div>
                        <div>
                            1) Points; 2) Goal difference; 3) Goals scored;
                            <br />
                            If the champions, relegated teams or qualified teams for UEFA competitions
                            cannot be determined by rules 1 to 3,
                            rules 4.1 to 4.3 are applied – 4.1) Points gained in head to head record
                            between such teams; 4.2) Away goals
                            scored in head to head record between such teams; 4.3) Play-offs

                        </div>
                    </div>
                </div>
            </div>
                    
        );
}
