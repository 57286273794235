import React,{Component} from 'react';
import Highcharts from '../../sharedfiles/customhighcharts';


class GoalScoreTimeGraph extends Component{
    componentDidMount(){
        if(this.props.categories && 
            this.props.categories.length>0 && 
            this.props.data && 
            this.props.data.length>0){
             this.loadGraph();
         }
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.categories && 
           this.props.categories.length>0 && 
           this.props.data && 
           this.props.data.length>0 &&
           JSON.stringify(this.props.categories)!==JSON.stringify(prevProps.categories) &&
           JSON.stringify(this.props.data)!==JSON.stringify(prevProps.data)){
            this.loadGraph();
        }
    }
    loadGraph(){
        var myChart =  new Highcharts.Chart({
            chart: {
                type: 'bar',
                renderTo: this.props.loadin,
                backgroundColor:"rgba(255, 255, 255, 0.0)",
            },
            title: {
                text: '',
                
            },
            xAxis: {
                gridLineWidth:0,
                categories: this.props.categories,
                lineWidth: 1,
                lineColor:this.props.color,
                tickColor: this.props.color,
                tickLength:5,
                tickWidth: 1,
                labels:{
                    useHTML:true,
                    style:{
                         color:"#fff",
                         fontSize:"12px",
                        //  fontFamily: '"Montserrat", sans-serif',
                    }
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                gridLineWidth:0,
                lineWidth: 1,
                lineColor:this.props.color,
                tickColor: this.props.color,
                tickLength:5,
                tickWidth: 1,
                min: 0,
                max:8,
                labels:{
                    enabled:true,
                    style:{
                         color:"#fff",
                         fontSize:"12px",
                        //  fontFamily: '"Montserrat", sans-serif',
                    }
                },

                
            },
            tooltip: {
                enable:false
            },
            legend: {
                enabled:false,
                
            },
            colors: [this.props.color],
            tooltip:{
                enabled:false
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        color: '#fff',
                        textAlign:'left',
                        style:{
                            textOutline:'0px',
                            fontWeight:'normal',
                            fontSize:"12px"
                        },
                    },
                    borderWidth:0,
                }
            },
            series: [{
                name: '',
                data: this.props.data
            }]
        });
    }
    render(){
        return (
            <div id={this.props.loadin} style={{"width":"640px", "height":"400px"}}></div>       
        );
    }
    
        
}

export default GoalScoreTimeGraph;
