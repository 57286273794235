import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {languageId,channelId} from './sharedfiles/constants'
import { getBanner } from './layouts/matchSlice'
import { setTabInfo, getTeamData } from './components/playeranalysis/PlayerAnalysisSlice';

export const AppSlice = createSlice({
  name: 'app',
  initialState: {
    selectedMainTab: 'match',
    eventInfo : {
      countryId: '',
      competitionId: '',
      eventId: ''
    },
    tempEventInfo:{
      countryId: '',
      competitionId: '',
      eventId: ''
    },
    loader: true,
    staticKeyWords:{},
    languageId:languageId
  },
  reducers: {
    setMainTab: (state, action) => {
      state.selectedMainTab = action.payload;
    },
    setEventId:(state,action)=>{
      var data = {
        countryId: action.payload.countryId,
        competitionId: action.payload.competitionId,
        eventId: action.payload.eventId
      }
      state.eventInfo = data 
      if(action.payload.countryId){
        state.tempEventInfo = data
      }
      // state.eventId = action.payload?action.payload:'';
    },
    setTempCountryInfo:(state,action)=>{
      state.tempEventInfo.countryId = action.payload
    },
    setLanguage:(state,action)=>{
      state.languageId = action.payload;
    },
    setTempCompetitionInfo:(state,action)=>{
      state.tempEventInfo.competitionId = action.payload
    },
    setLoader:(state,action)=>{
      state.loader = action.payload;
    },
    setStaticKeyWords:(state,action)=>{
      state.staticKeyWords = action.payload;
    }
  }
});

export const { setMainTab, setEventId, setTempCountryInfo, setTempCompetitionInfo, setStaticKeyWords, setLoader, setLanguage } = AppSlice.actions;

export const getEvenId = (eventInfo,from,props,bannerData,tabInfo) => dispatch => {
  dispatch(setEventId(eventInfo));
  if(from == 'fromdropdown'){
    dispatch(getBanner(eventInfo.eventId,props.languageId))  
    var path = window.location.pathname;
    path = path.split("/");
    path[3]=eventInfo.countryId;
    path[4]=eventInfo.competitionId;
    path[5]=eventInfo.eventId;
    if(window.location.pathname.includes('playeranalysis')){
      path[8]=(path[7]=='home')?bannerData.homeTeamId:bannerData.awayTeamId;
      dispatch(setTabInfo({
          mainTab:path[7],
          teamId:(path[7]=='home')?bannerData.homeTeamId:bannerData.awayTeamId,
          subTab:tabInfo.subTab
      }))
      path = path.join("/")
      props.history.push(path)
      // dispatch(getTeamData(tabInfo));
    }else{
      path = path.join("/")
      props.history.push(path)
    }
    
  }else{
    props.history.push('/'+props.match.params.lang+'/matchcenter/'+eventInfo.countryId+'/'+eventInfo.competitionId+'/'+eventInfo.eventId+'/htoh/head2head')
  }
}

export const resetEventId = (text,toggleMode) => dispatch => {
  dispatch(setEventId({
      countryId: '',
      competitionId: '',
      eventId: ''
    }))
  dispatch(setMainTab(text));
  dispatch(toggleMode(true));
}

export const reSetMainTab = () => dispatch => {
  var path = window.location.pathname;
  var partialUrl = path.includes('tournament')?'tournament':path.includes('htohsearch')?'htohsearch':'match';
  dispatch(setMainTab(partialUrl));
}

export const navigateToTournament = (competitionId,props,filterBy,from) => dispatch => {
  if((filterBy==2 && from=='tournament') || (filterBy==1 && from == 'date')){
    props.history.push('/'+props.match.params.lang+"/tournament/league"+"/"+competitionId+"/standings");
  }
}

export default AppSlice.reducer;
