import React,{Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import Loading from '../loading/Loading';

class Standings extends Component{
    componentWillMount(){
    }
    componentDidUpdate(prevProps, prevState) {
        
    }
    rowNavigation(link){
        this.props.history.push(link);
    }
    render(){
        console.log("standings table=",this.props.standingColumns)
        
        return <table className={"tableDefault" +(this.props.isWhiteBG?"":" tableSeasonal-stats")}>
        <thead>
            <tr>
                {
                    this.props.standingColumns.map((item)=>{
                        if(item.isHeaderImg){
                            return <th className={item.className}><img src={item.column} alt="graph"/></th>
                        }else{
                            if(this.props.selectedTable.tableId==1 && this.props.selectedBetTypeFilter==1 && item.column==this.props.staticKeyWords.pts){
                            }else{
                                return <th className={item.className}>{item.column}</th>
                            }
                        }
                        
                    })
                }
            </tr>
        </thead>
        <tbody>

            {
                this.props.standingsTableData.length?this.props.standingsTableData.map((rowData,rowIndex)=>{
                    return <tr data-href className={ this.props.isTournament?
                        (this.props.type=='team'?(this.props.teamId==rowData.teamId?'neutral':''):''):
                        (this.props.homeTeamId==rowData.teamId?"homeBg":this.props.awayTeamId==rowData.teamId?"awayBg":"")}
                               onClick={this.props.isRowClick?this.rowNavigation.bind(this,this.props.rowNavigation.replace("[:id]",rowData[this.props.paramKey])):""}>
                        {
                            this.props.standingColumns.map((column,columnIndex)=>{
                                if(column.isSplit){
                                    return <td key={columnIndex} class="formStatus">
                                            <div class="team-lastForm">
                                                {
                                                    rowData[column['key']] && rowData[column['key']].split(',').map((item, subColumnIndex)=>{
                                                        return <span key={subColumnIndex} className={"status"+(item==="W"?" win":item==="D"?" draw":" loss")}>{this.props.staticKeyWords[item.toLowerCase()]}</span>
                                                    })
                                                }
                                            </div>
                                        </td>
                                }else if(column.showImg){
                                   return <td class="text-left">
                                                {rowData[column['imgKey']]?<img class="player-pic" src={rowData[column['imgKey']]} alt="player" />:""}
                                                {rowData[column['key']]}
                                            </td>
                                }else if(column.isTopScorer){
                                    if(rowIndex>=10){
                                    return <td className={column.className}>
                                            <div>{rowData[column['key']]!=="" && rowData[column['key']] !==0? rowData[column['key']]:0}</div>
                                        </td>
                                    }
                                }else{
                                    if(this.props.selectedTable.tableId==1 && this.props.selectedBetTypeFilter==1 && column["key"]=='teamPoints'){
                                    }else{
                                        return <td>
                                            <div>{rowData[column['key']]!=="" && rowData[column['key']] !==0? rowData[column['key']]:0}</div>
                                        </td>
                                    }
                                }
                                
                            })
                        }
                    </tr>
                    
                }):
                <tr>
                    <td colspan={this.props.standingColumns.length}>
                        <Loading isLoader={this.props.loader} isNoData={!this.props.loader && !this.props.standingsTableData.length} temporarilyNoData={this.props.staticKeyWords.temporarilyNoData}/>
                    </td>
                </tr>
            }
        </tbody>
    </table>  
    }
    
}
function mapStateToProps(state) {
    return {
        loader: state.app.loader,
        homeTeamId: state.match.bannerData.homeTeamId,
        awayTeamId: state.match.bannerData.awayTeamId,
        staticKeyWords: state.app.staticKeyWords,
        selectedTable: state.seasonalstats.selectedTable,
        selectedBetTypeFilter: state.standings.selectedBetTypeFilter
    };
  }
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: bindActionCreators({}, dispatch)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Standings);
